import {cfputils, useDialog} from "../components/utils/cfputils";
import {useEffect, useState} from "react";
import {Cmac} from "../chatai/utils/cmac";
import {Buffer} from "buffer";
import {gConfig} from "../chatai/utils/config";


export const useGDev = (gStripe,gAuth) => {
    const setIsOpenDialog = useDialog();
    const [apiKey, setApiKey] = useState({apiKeyValue:'',apiKeyId:''})
    const [useCount, setUseCount] = useState(0)
    const [apiResponse, setApiResponse] = useState({})

    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
            setUseCount(0)
            setApiResponse({})
        }
    }, [gAuth.loginState])


   useEffect(()=>{
       if (gStripe.customer.id){
           getKey()
       }

   },[gStripe.customer.id])

    const getKey = async () => {
        cfputils.log("获取用户KEY", gStripe.customer.id)
        let id = gStripe.customer.id
        let email = gStripe.customer.employeeEmail
        if (!id)return
        cfputils.log("获取用户KEY", id)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/stripe/key', {cid: id,email:email}), setIsOpenDialog)
            .then((d) => {
                cfputils.log("获取用户KEY", d)

                setApiKey(d)
                getUseCount()


            }).catch(e => {

            cfputils.err("获取用户KEY", e)
        })
    }
    const changeKey = async () => {
        let email = gStripe.customer.employeeEmail
        let id = gStripe.customer.id
        cfputils.log("获取用户KEY", id)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/stripe/key', {cid: id, update: true,email:email}), setIsOpenDialog)
            .then((d) => {
                cfputils.log("获取用户KEY", d)
                setApiKey(d)


            }).catch(e => {

            cfputils.err("获取用户KEY", e)
        })
    }

    const getState = async (uuid) => {
        cfputils.log("getState", uuid,apiKey.apiKeyValue)

        cfputils.makeAll(() => cfputils.apiGet(`/touchpro/apifun/getshadow`, {

            headers: {
                'x-api-key': apiKey.apiKeyValue,
            },
            queryStringParameters: {
                isBiz: true,
                device_id:uuid

            }
        }), setIsOpenDialog)
            .then((d) => {
                cfputils.log("getState", d)
                setApiResponse(d.data)
                getUseCount()
                //   setUseCount(d)
            }).catch(e => {
            cfputils.err("getState", e)
        })
    }
    const getUseCount = async () => {
        let id = gStripe.customer.id
        cfputils.log("获取使用数量", id)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/stripe/usekey', {cid: id}), setIsOpenDialog)
            .then((d) => {
                cfputils.log("获取使用数量", d)
                setUseCount(d)

            }).catch(e => {
            cfputils.err("获取使用数量", e)
        })
    }

    const getHistory = async (uuid) => {
        cfputils.log("获取使用getHistory", uuid,apiKey.apiKeyValue)
        cfputils.makeAll(() => cfputils.apiGet('/touchpro/apifun/history', {

            headers: {
                'x-api-key': apiKey.apiKeyValue,
            },
            queryStringParameters: {
                device_id: uuid,
                page: 0,
                lg: 5,
                isBiz:true
            }
        }), setIsOpenDialog)
            .then((d) => {
                cfputils.log("获取使用getHistory", d)
                setApiResponse(d.data)
                getUseCount()
                //   setUseCount(d)
            }).catch(e => {
            cfputils.err("获取使用数量", e)
        })
    }
    const cmdLock = async (cmd,uuid,sescretKey) => {
        const sign = await Cmac.cmacTime(sescretKey)
       let  email=  gStripe.customer.employeeEmail
        cfputils.log("cmdLock", uuid,email,sign)

        cfputils.makeAll(() => cfputils.apiPostHeader('/touchpro/apifun/cmd', {

            cmd: cmd,
            history: Buffer.from(email).toString('base64'),
            sign:  sign.toString(),
            device_id:uuid


        },{
            'x-api-key': apiKey.apiKeyValue,

        }), setIsOpenDialog)
            .then((d) => {
                cfputils.log("cmdLock", d)
                setApiResponse(d)
                getUseCount()
                //   setUseCount(d)
            }).catch(e => {
            cfputils.err("cmdLock", e)
        })
    }
    return {

        apiKey,
        changeKey,
        getState,
        useCount,
        apiResponse,
        getHistory,
        cmdLock,

    }
}

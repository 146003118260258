import {useEffect, useState} from "react";

import {cfputils, useDialog} from "../components/utils/cfputils";
import {gConfig} from "../chatai/utils/config";
import {passwordPath} from "./use_cfp_fpctrl";

const cardPath='/touchpro/card'
export const useCfpCardCtrl = (gAuth,gCfp,gCfpfp) => {
    const setIsOpenDialog = useDialog();
    const [allCards, setallCards] = useState([])

    const [temDeviceCards, setTemDeviceCards] = useState([])
    const [mmCards, setmmCards] = useState([])


   useEffect(()=>{
       if (gAuth.isClearData){
           setallCards([])
       }

   },[gAuth.isClearData])
    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
           setallCards([])
            setTemDeviceCards([])
            setmmCards([])
        }
    }, [gAuth.loginState])



    useEffect(() => {
        getAllCards()

    }, [gCfp.cfpDevices])

    function processDevices(devices) {
        const result = [];

        // 创建一个映射，用于存储每个 cardID 对应的信息
        const cardMap = devices.reduce((acc, device) => {

            const { cardID, deviceID, cardType, name, timestamp,memberID } = device;
            if (!acc[cardID]) {
                acc[cardID] = {
                    cardID,
                    cardType,
                    name: name || '', // 初始化为空字符串
                    timestamp,
                    memberID: memberID || '', // 初始化为空字符串
                    uuids: []
                };
            }else {
                if (!acc[cardID].name && name) {
                    acc[cardID].name = name;
                }
                if (!acc[cardID].memberID && memberID) {
                    acc[cardID].memberID = memberID;
                }
            }
            acc[cardID].uuids.push(deviceID);
            return acc;
        }, {});


        for (const card of Object.values(cardMap)) {
            result.push(card);
        }

        return result;
    }
//获取所有卡片
    const getAllCards = () => {
        let devices = gCfp.cfpDevices.map(item => item.deviceUUID).join(",");
        //   let   uuids=gCfp.cfpDevices.map(item=>item.deviceUUID)
        cfputils.log("获取所有卡片", gCfp.cfpDevices,devices)
        if (devices.length>0){
            cfputils.makeAll(() => cfputils.apiGet(cardPath,{ queryStringParameters: {devices: devices}}), setIsOpenDialog)
                .then((d) => {
                    cfputils.log("获取所有卡片", d)

                    setallCards(processDevices(d.data))
                }).catch(e => {
                cfputils.err("获取所有卡片错误", e)
            })
            cfputils.makeAll(() => cfputils.apiGet(passwordPath,{ queryStringParameters: {devices: devices}}), setIsOpenDialog)
                .then((d) => {
                    cfputils.log("获取所有密码", d)
                    gCfpfp.setdevicePasswords(d.data)

                  //  setallCards(processDevices(d.data))
                }).catch(e => {
                cfputils.err("获取所有密码", e)
            })
        }
    }

    const getMemberCards = (mmid) => {

        let  list=[]
       allCards.map(item=>{
           if (item.memberID===mmid){
               list.push(item)
           }

       })
     setmmCards(list)
    }
    const updateCards = (item,isChange) => {

        console.log("updateCards",item)
          cfputils.makeAll(() => cfputils.apiPost(cardPath, item), setIsOpenDialog)
            .then((d) => {
                console.log("updateCards2",d)
                setallCards(prevState => prevState.map(obj=>obj.cardID===item.cardID?item:obj))
                setTemDeviceCards(prevState => prevState.filter(obj=>obj.cardID!==item.cardID))


            }).catch(e => {
            cfputils.err("更新卡片错误", e)
        })
    }





    const updateName = (deviceUUID, item) => {
        setallCards((prevAllCards) => {
            return prevAllCards.map(obj =>
                obj.cardID === item.id
                    ? { ...obj, name:item.n}
                    : obj
            );
        });
    }
    const addCards = async (deviceUUID, item) => {
        cfputils.log("添加卡片", deviceUUID, item, allCards);

        setallCards((prevAllCards) => {
            let isFind = prevAllCards.find(obj => obj.cardID === item.id);
            cfputils.log("添加卡片isFind", isFind);
            if (isFind) {
                return prevAllCards.map(obj =>
                    obj.cardID === item.id
                        ? { ...obj, uuids: obj.uuids.some(i => i === deviceUUID) ? obj.uuids : [...obj.uuids, deviceUUID] }
                        : obj
                );
            } else {
                let obj = { cardID: item.id, name: item.n, cardType: item.t, uuids: [deviceUUID] };
                return [...prevAllCards, obj];
            }
        });

        setTemDeviceCards((prevTemDeviceCards) => {
            let isFindTem = prevTemDeviceCards.find(obj => obj.cardID === item.id);
            if (isFindTem) {
                return prevTemDeviceCards.map(obj =>
                    obj.cardID === item.id
                        ? { ...obj, uuids: obj.uuids.some(i => i === deviceUUID) ? obj.uuids : [...obj.uuids, deviceUUID] }
                        : obj
                );
            } else {
                let obj = { cardID: item.id, name: item.n, cardType: item.t, uuids: [deviceUUID] };
                return [...prevTemDeviceCards, obj];
            }
        });
    };

    const delCards = async (deviceUUID, item) => {
        cfputils.log("删除卡片", deviceUUID, item, allCards);
        setallCards(prevState => prevState.map(obj => {
            if (obj.cardID === item.id) {
                return { ...obj, uuids: obj.uuids.filter(i => i !== deviceUUID) };
            } else {
                return obj;
            }
        }).filter(obj => obj.uuids.length !== 0));

        setTemDeviceCards(prevState => prevState.map(obj => {
            if (obj.cardID === item.id) {
                return { ...obj, uuids: obj.uuids.filter(i => i !== deviceUUID) };
            } else {
                return obj;
            }
        }).filter(obj => obj.uuids.length !== 0));
    };
    return {

         updateName,
        getMemberCards,
        updateCards,
        delCards,
        addCards,
        allCards,

        temDeviceCards,
        setTemDeviceCards,
        mmCards,


    }
}

import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import {gUtils} from "../components/utils/gutils";

export const useLoginEmployee = (gStripe, gAuth) => {
  const initAccess = { access: [], tag: [], companyID: undefined }
  const [loginInfo, setLoginInfo] = useState(initAccess)
  const [isPending, setIsPending] = useState(false)

  const newTags=(res)=>{
    if (res===''||res===undefined)return  res
     if (res.tag.length>0){
       if (res.tag[0]==='オーナー'||res.tag[0]==='マネージャー'){
         res.access=gUtils.allTags
       }
     }

     return res
  }
  const getEmployeeInfo = (companyID) => {
    setIsPending(true)
    //console.log("获取登录企业信息",companyID)
    API.get('Attendance', `/employee/info`, {
      queryStringParameters: {
        companyID,
        subUUID: gAuth.loginSub,
      },
    })
      .then((res) => {

      console.log("获取登录企业信息",res)

        res === '' ? setLoginInfo(initAccess) : setLoginInfo(newTags(res))
        setIsPending(false)
      })
      .catch((err) => console.log(err))
  }




  const reset = () => {
    setLoginInfo(initAccess)
    setIsPending(false)
  }

  useEffect(() => {

    if (gStripe.customer.id) {
     // setLoginInfo(gStripe.customer)
     getEmployeeInfo(gStripe.customer.id)
    }
  }, [gStripe.customer.id])

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  return {
    loginInfo,
    setLoginInfo,
    isPending,
  }
}

import {useEffect, useState} from 'react'
import {API} from 'aws-amplify'
import {cfputils, useDialog} from "../components/utils/cfputils";


let name = 'currLogin'
export const useStripeInfo = (gAuth,ts) => {
    const initCustomer = {
        id: null,
        invoice_settings: {default_payment_method: null},
    }
    const initSubscription = {
        items: {data: [{plan: {id: null}}]},
        metadata: {downgradePlan: null},
        status: '',
    }
    const initInvoiceComing = {
        totalExcludingTax: 0,
        total: 0,
    }
    const setIsOpenDialog = useDialog();
    const [isPending, setIsPending] = useState(false)
    const [customer, setCustomer] = useState(initCustomer)
    const [subscription, setSubscription] = useState(initSubscription)
    const [usage, setUsage] = useState(0)
    const [invoiceComing, setInvoiceComing] = useState(initInvoiceComing)
    const [count, setCount] = useState(0)
    const [cardList, setCardList] = useState([])
    const [levelInfo, setLevelInfo] = useState({})
    const [isOwer, setIsOwer] = useState(false)
    const [levelConfig,setLevelConfig]= useState({})
    const [isNewBiz,setIsNewBiz]= useState(true)

    const item = typeof window !== 'undefined' ? localStorage.getItem('currLogin') : null

    const getUsage = (subscriptionID) => {
        API.get('Attendance', `/usage`, {
            queryStringParameters: {subscriptionID},
        })
            .then((res) => {
                setUsage(res)
            })
            .catch((err) => console.log(err))
    }
    const getApiLevelInfo = (idm,isConvert=false) => {
        cfputils.log("getLevelInfo", idm)
        let id = idm !== undefined ? idm : customer.id

        if (!id) return
        cfputils.log("getLevelInfo", id)

        cfputils.makeAll(() => cfputils.apiGet('/touchpro/stripe/config',
            {
                queryStringParameters: {
                    customerId: id,
                    isConvert:isConvert
                }
            }
        ), setIsOpenDialog)
            .then((d) => {
                setIsFirstIndex(true)
                cfputils.log("getLevelInfo", d,d.data.isNewBiz)
                setLevelConfig(d.data.config)
                setLevelInfo(d.data)
                setIsNewBiz(d.data.isNewBiz)
            }).catch(e => {
            cfputils.err("getLevelInfo", e)
        })
    }
    const changeName = (name,call) => {
        if (!isOwer){

            ts({open: true, msg: '您无权修改'})
            return;
        }

        let companyID = customer.companyID
        let subUUID = customer.subUUID
        if (!companyID) return
        cfputils.log("changeName", name)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/changename',
            {
                companyID: companyID,
                subUUID: subUUID,
                name: name

            }
        ), setIsOpenDialog)
            .then((d) => {
                cfputils.log("changeName", d)
                customer.name = name
                setCustomer(customer)
                if (call)call(true)
                gAuth.setCompaniesList(prevState => prevState.map(item =>
                    item.companyID === companyID ? { ...item, name: name } : item
                ));

            }).catch(e => {
            if (call)call(false)
            cfputils.err("changeName", e)
        })
    }
    const updateLevel = (level,isUpgrade,isCancel=false) => {
        const subId = customer.subscriptions
        cfputils.log("updateLevel", subId, level)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/stripe/updatelevel',
            {
                subId: subId,
                isUpgrade:isUpgrade,
                level: level,
                isCancel:isCancel,
                customerId:customer.id
            }
        ), setIsOpenDialog)
            .then((d) => {
                cfputils.log("updateLevel", d)

                if (d){
                    if (d.subId){

                        setCustomer(prevState =>{return {...prevState,subscriptions:d.subId}})

                    }
                    if (isCancel){

                    }
                    setLevelInfo(d)
                }else {
                    ts({open: true, msg: '取消订阅成功'})

                }

            }).catch(e => {
            cfputils.err("updateLevel", e)
        })


    }
    const getInvoiceComing = (customer) => {
        API.get('Attendance', `/invoice`, {
            queryStringParameters: {customer},
        })
            .then((res) => {
                setInvoiceComing(res)
            })
            .catch((err) => console.log(err))
    }

    const getClientSecret = (call) => {
        let id = customer.id
        cfputils.log("getClientSecret", id)
        cfputils.makeAll(() => cfputils.apiGet('/touchpro/stripe/clientsecret',
            {
                queryStringParameters: {
                    customerID: id,
                }
            }
        ), setIsOpenDialog)
            .then((d) => {
                if (d.code === 200) {
                    if (call) call(d.data)
                }
            }).catch(e => {
            cfputils.err("getClientSecret", e)
        })
    }
    const getCardList = () => {
        let id = customer.id
        cfputils.log("getCardList", id)
        cfputils.makeAll(() => cfputils.apiGet('/touchpro/stripe/cardlist',
            {
                queryStringParameters: {
                    customerID: id,
                }
            }
        ), setIsOpenDialog)
            .then((d) => {
                cfputils.log("getCardList", d)
                if (d.code === 200) {
                    setCardList(d.data)
                }
            }).catch(e => {
            cfputils.err("getCardList", e)
        })
    }
    useEffect(()=>{
        if (customer.id){
            getCardList()
        }
    },[customer.id])
    useEffect(async ()=>{
        if (!gAuth.isLoginState){

            setIsFirstIndex(false)
        }else {


            if (!isFirstIndex){
                await  getStripeInfo()
            }
        }


    },[gAuth.isLoginState])
    const firstsettingLoad = () => {


    }
    const changeDefaultPay = (payId) => {
        let cid = customer.id
     //   console.log("changeDefaultPay", cid, payId)
        let isFind = cardList.find(item => item.id === payId)
        if (isFind && isFind.isDefaultPay) {
            return
        }
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/stripe/changecard',
            {
                customerID: cid,
                defaultPaymentMethod: payId
            }
        ), setIsOpenDialog)
            .then((d) => {

                let isFind = cardList.find(item => item.id === payId)
                if (isFind) {
                    setCardList(prevState => prevState.map(item => {
                        if (item.id === payId) {

                            return {...item, isDefaultPay: true}
                        }
                        return {...item, isDefaultPay: false}
                    }))
                } else {
                    getCardList()
                }
            }).catch(e => {
            cfputils.err("changeDefaultPay", e)
        })
    }
    const delCard = async (cardId) => {
        API.del('Attendance', `/credit_card`, {
            body: {paymentId: cardId},
        }).then(() => {
            let isDelPay = cardList.find(item => item.id === cardId).isDefaultPay
            setCardList(prevState => prevState.filter(item => item.id !== cardId))
            if (isDelPay) {
                let pay = cardList.filter(item => item.id !== cardId)
                if (pay.length > 0) {
                    changeDefaultPay(pay[0].id)
                }
            }
        })
            .catch((err) => {
            })

    }
    const isOwerByTag=(tag)=>{
        try {
            if (tag[0]==='オーナー'){
                setIsOwer(true)
            }else {
                setIsOwer(false)
            }

        }catch (e){
            setIsOwer(false)
        }

    }
    const getUserInfo = async (loginMail) => {
        if (loginMail === undefined) {
            loginMail=  localStorage.getItem(name)
       //     loginMail = typeof window !== 'undefined' ? localStorage.getItem('name') : null
        }
        if (!loginMail) return
        //52e2899e-5a4d-45d2-80aa-4cba7afabf13
        cfputils.log("获取用户信息", loginMail)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/stripe', {stripeInfo: loginMail}), setIsOpenDialog)
            .then((d) => {
                cfputils.log("获取用户信息", d)
                setCustomer({...d, id: d.companyID, subscriptions: d.subscriptionId})
                localStorage.setItem(name, d.companyID)
                getApiLevelInfo(d.companyID)
                isOwerByTag(d.tag)
                gAuth.getCompanies()
            }).catch(e => {

            cfputils.err("获取用户信息", e)
        })
    }
    const [isFirstIndex,setIsFirstIndex]=useState(true)

    const getStripeInfo = (stripeInfo) => {
        getUserInfo(stripeInfo)
        /* setIsPending(true)
         API.get('Attendance', `/stripe_info`, {
           queryStringParameters: { stripeInfo },
         })
           .then((res) => {
             console.log("获取用户信息",res)
             setCustomer(res.customer)
             setSubscription(res.customer.subscriptions.data[0])
             getUsage(res.customer.subscriptions.data[0].id)
             getInvoiceComing(res.customer.id)
             if (typeof window !== 'undefined') {
               localStorage.setItem('currLogin', res.customer.id)
               localStorage.setItem('currAccountName', res.customer.name)
             }
             if (gAuth.companiesList.length === 0) {
               gAuth.getCompanies()
             }
             setIsPending(false)
           })
           .catch((err) => {
             console.log(err)
           })*/
    }

    const reset = () => {
        setCustomer(initCustomer)
        setSubscription(initSubscription)
        setIsPending(false)
        setUsage(0)
        setCount(0)
    }

    useEffect(() => {
        if (gAuth.loginState === 'signOut') {
            reset()
        }

      //  console.log("当前登录状态", gAuth.loginState)
    }, [gAuth.loginState])

    useEffect(() => {
        // 頁面刷新，依據登入的使用者再渲染一次(真的有用)
     //   console.log("用户信息", item)

        if (item) {
            getStripeInfo(item)
        }
    }, [item])

    return {
        customer,
        setCustomer,
        subscription,
        setSubscription,
        getStripeInfo,
        isPending,
        getUsage,
        usage,
        getInvoiceComing,
        invoiceComing,
        count,
        setCount,
        getClientSecret,

        cardList,
        delCard,
        changeDefaultPay,
        getApiLevelInfo,
        levelInfo,
        updateLevel,
        changeName,
        firstsettingLoad,

        isOwer,
        levelConfig,
        isNewBiz
    }
}

import {useEffect, useState} from 'react'
import {API} from 'aws-amplify'
import {cfputils, useDialog} from "../components/utils/cfputils";
import {gConfig} from "../chatai/utils/config";

export const useCompanyEmployee = (gLoginEmployee, gAuth, gStripe,ts) => {

    const init = {
        count: 0,
        Items: [],
    }
    const [employees, setEmployees] = useState(init)
    const [isPending, setIsPending] = useState(false)
    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
            setEmployees(init)
            setIsPending(false)
        }
    }, [gAuth.loginState])

    const setIsOpenDialog = useDialog()
     const findNameById=(id)=>{
        let  find=employees.Items.find(item=>item.subUUID===id)


         return find?find.employeeName :""
     }

    const changeMemberInfo = (bodyInfo, call) => {
    // console.log("当前用户信息",bodyInfo)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/member',
            {info:bodyInfo, op: 'change'}
        ), setIsOpenDialog)
            .then((d) => {
                let nitem = employees.Items.map(item => {
                    if (bodyInfo.subUUID === item.subUUID) {
                        return {
                            ...item,
                            ...bodyInfo
                        };
                    }
                    return item
                })
                setEmployees({
                    count: employees.count,
                    Items: nitem
                })
                if (call) call()

            }).catch(e => {
            console.error("get employees memeber", e)
        })
      /*  cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/changename',
            bodyInfo
        ), setIsOpenDialog)
            .then((d) => {

                cfputils.log("changeName", d)

            }).catch(e => {
            cfputils.err("changeName", e)
        })*/
    }

    const getEmployees = () => {
        let companyID = gLoginEmployee.loginInfo.companyID
        if (!companyID) return

        console.log("获取企业getEmployees")
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/member',
            {companyID, op: 'get'}
        ), setIsOpenDialog)
            .then((d) => {
                console.log("获取企业getEmployees",d)
                setEmployees({
                    count: d.length,
                    Items: d
                })


            }).catch(e => {
            console.error("get employees memeber", e)
        })

    }

    const delVisiterApi=(list,call)=>{
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/member',
            { op: 'del',list:list}
        ), setIsOpenDialog)
            .then((d) => {

   if (call)call()

            }).catch(e => {
            console.error("get employees memeber", e)
        })

    }
    const getFinger=(mmids,list,call)=>{
      cfputils.log("getFinger",mmids,list)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/getFinger',
            { mmids:mmids,list:list}
        ), setIsOpenDialog)
            .then((d) => {
                cfputils.log("getFinger",d)
                if (call)call(d)

            }).catch(e => {
            console.error("getFinger", e)
        })

    }
    useEffect(() => {
        if (gLoginEmployee.loginInfo.companyID) {
            getEmployees(
                gLoginEmployee.loginInfo.companyID,
                gLoginEmployee.loginInfo.access,
                null,
            )
        }
    }, [gLoginEmployee.loginInfo.companyID])




    const delMm = async (items) => {
        setIsOpenDialog(true)
        let   delVisiter=[]
        let   delOther=[]
       items.map(item=>{

            if (item.tag.includes('ゲスト')){

                delVisiter.push(item)
            }else {
                delOther.push(item)
            }

        })
        cfputils.log('删除成员', items)



            // 使用Promise.all来等待所有的删除操作都完成
            try {
               if (delOther.length>0){
                   const deletePromises = delOther.map(
                       (item) =>
                           API.del('Attendance', `/employee`, {
                               body: {
                                   companyID: gStripe.customer.id,
                                   subUUID: item.subUUID,
                               },
                           })
                   )
                 await Promise.all(deletePromises)
                   let newResult = employees.Items.filter(item => (!delOther.some(data => data.subUUID === item.subUUID)))
                   let data = {
                       count: newResult.length,
                       Items: newResult,
                   }
                   setEmployees(data)
               }


                if (delVisiter.length>0){
                    delVisiterApi(delVisiter,()=>{
                        let newResult = employees.Items.filter(item => (!delVisiter.some(data => data.subUUID === item.subUUID)))
                        let data = {
                            count: newResult.length,
                            Items: newResult,
                        }
                        setEmployees(data)

                    })
                }


            } catch (e) {
                cfputils.err('删除成员', e)
            } finally {
                setIsOpenDialog(false)

            }



        // 创建一个数组来存储所有的删除设备的Promise


    }
    const addMember = (body,call) => {
        body.op = 'add'
        body.mainEmail=gStripe.customer.employeeEmail


        console.log("添加成员",body)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/member',
            body
        ), setIsOpenDialog)
            .then((d) => {
               if (employees.Items.some(item=>item.employeeEmail===body.employeeEmail)){
                   setEmployees(prevState => {
                       return {
                           count: prevState.count, // 假设替换不改变总数
                           Items: prevState.Items.map(item =>
                               item.employeeEmail === body.employeeEmail ? d : item
                           )
                       };
                   });
               }else {
                   setEmployees(prevState => {

                       return {  count: prevState.count+1,
                           Items: [...prevState.Items,d]}
                   })
               }


                // getEmployees()
                if (call)call(d)
            }).catch(e => {
            if (call)call(null,e)
            if ( e === "Limit Exceeded") {
                console.log('addMember 社員人數已經到上限了')
                ts({ open: true, msg: "ユーザー数の上限に達しました。プランのアップグレードが必要です。" })
            } else {
                console.log('addMember 錯誤',e)
                ts({ open: true, msg: "ユーザーの追加に失敗しました。再度お試しください。" })
            }
        })
    }
    function getCurrentFormattedTime() {
        const now = new Date();

        // 获取月份和日期
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以需要加 1
        const day = String(now.getDate()).padStart(2, '0');

        // 获取小时和分钟
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');



        return `${month}/${day} ${hours}:${minutes}`;
    }
    const shareLock = async (item,call) => {
        if (item.keyName === null) {
            item.keyName = getCurrentFormattedTime()
        }
       // console.log("shareLock", item)
        item.op='add'
       let result = await cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/sharelock', item), setIsOpenDialog)
      //  console.log("shareLock", result)
        if (result===''){
            if (call)call()
        }
    }
    const groupLock = async (item,call) => {

        item.op='group'
        let result = await cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/sharelock', item), setIsOpenDialog)
        if (call)call(result)
    }
    const getLock = async (companyID,subUUID,call) => {

        let item={
            op:'get',
            companyID:companyID,
            subUUID:subUUID
        }

        let result = await cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/sharelock', item), setIsOpenDialog)


        if (call)call(result)

    }

    const delLock = async (item,call) => {

        item.op='del'
        let result = await cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/sharelock', item), setIsOpenDialog)

        if (result){
            if (call)call(result)
        }
    }
    const memberIDToName= (memberID)=>{
        if (!memberID)return "-"



        let  findItem=employees.Items.find(item=>item.subUUID===memberID)
        return  findItem?findItem.employeeName:'-'

    }
    return {
        employees,
        getEmployees,
        isPending,
        setIsPending,
        delMm,
        addMember,
        changeMemberInfo,
        shareLock,
        delLock,
        getLock,
        groupLock,
        setIsOpenDialog,
        findNameById,
        getFinger,
        memberIDToName,
    }
}
//



import { saveAs } from "file-saver"
import Papa from "papaparse"
import {cfputils} from "./cfputils"
import {gUtils} from "./gutils";
// import { useLocation } from 'react'
import * as XLSX from 'xlsx';
import {wordConfig} from "../../config/configFont";


function downloadCsv(){
    try {
        let data = []
        const pathname = window.location.pathname

        if (pathname === "/employees") {
            data = [{
                'ユーザー名': "",
                'メールアドレス': "",
                '所属（任意）': "",
                '電話番号（任意）': ""
            }]
        } else if (pathname === '/cfp/cards' || pathname === '/cards') {
            data = [{
                'カード名': "",
                'ID': "",
                'ユーザー': ""
            }]
        } else if (pathname === '/cfp/passwords') {
            data = [{
                '暗証番号名': "",
                '暗証番号': ""
            }]
        } else {
            console.log("no setting pathname downloadCsv")
        }


        const csv = Papa.unparse(data)
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
        saveAs(blob, "sesamebiz.csv")

    } catch (error) {
        console.error('An error occurred:', error)
    }
}

function downloadExcelTemp(){
    try {
        let data = [];
        const pathname = window.location.pathname;

        if (pathname === "/employees") {
            data = [{
                'ユーザー名': "",
                'メールアドレス': "",
                '所属（任意）': "",
                '電話番号（任意）': ""
            }];
        } else if (pathname === '/cfp/cards' || pathname === '/cards') {
            data = [{
                'カード名': "",
                'ID': "",
                'ユーザー': ""
            }];
        } else if (pathname === '/cfp/passwords') {
            data = [{
                '暗証番号名': "",
                '暗証番号': ""
            }];
        } else {
            console.log("no setting pathname downloadExcel");
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'sesamebiz.xlsx');

    } catch (error) {
        console.error('An error occurred:', error);
    }
}


function downloadLists(list,isCsv=true){
    cfputils.log("hey下载数据",list)

    // 定義各header
    const headersUserGroup = {
        name: "グループ名",
        gid: "UUID",
        uuids: "紐付けているユーザー",
    }
    const headersDeviceGroup = {
        name: "グループ名",
        gid: "UUID",
        uuids: "紐付けているデバイス",
    }
    const headersUser = {
        employeeName: "ユーザー名",
        employeeEmail: "メールアドレス",
        subUUID: "サブUUID",
        tag: "ロール",
        department: "所属",
        phone: "電話番号",

    }
    const headersDevice = {
        deviceName: "デバイス名",
        deviceModel: "モデル",
        deviceUUID: "デバイスUUID",
        battery: "電池残量",
    }
    const headersDevice2 = {
        deviceName: "デバイス名",
        deviceModel: "モデル",
        deviceUUID: "デバイスUUID",
        battery: "電池残量",
    }
    const headersCfpDevice = {
        deviceName: "デバイス名",
        deviceModel: "モデル",
        deviceUUID: "デバイスUUID",
        battery: "電池残量",
      //  battery: "連携するセサミ",
    }
    const headersCfpCards = {
        name: "カード名",
        cardID: "ID",
        memberID: "ユーザー",
        uuids: "認証機器",
    }
    const headersFingers = {
        name: "指紋名",
        fingerID: "ID",
        // id: "インデックス",
        memberName: "ユーザー",
    }
    const headersPasswords = {
        name: "暗証番号名",
        // t: "インデックス",
        passwordID: "暗証番号",
    }
    const pathname = window.location.pathname
    var   isPw=false
    let newList = list.map(item => {
        let headers
        let newItem = {}


        if (pathname === '/employees/group') {
            headers = headersUserGroup
        } else if (pathname === '/devices/group') {
            headers = headersDeviceGroup
        } else if (pathname === '/employees') {
            headers = headersUser
        } else if (pathname === '/devices') {
            headers = headersDevice
        } else if (pathname === '/devices/groupdetails') {
            headers = headersDevice2
        } else if (pathname === '/cfp') {
            headers = headersCfpDevice
        }else if (pathname === '/employees/memberdetails') {
            headers = headersUser
        }else if (pathname === '/cfp/cards' || pathname === '/cards') {
            headers = headersCfpCards

            if (item.hasOwnProperty('uuids') && Array.isArray(item.uuids)) {
                // 有含'cus_'的數據不要顯示
                const filteredUuids = item.uuids.filter(uuid => !uuid.startsWith('cus_'))
                item.uuids = filteredUuids
            }

        } else if (pathname === '/cfp/fingers') {
            headers = headersFingers
        } else if (pathname === '/cfp/passwords') {
            isPw=true

            headers = headersPasswords
        } else {
            console.log("no setting pathname downloadLists")
        }

        // 根據標題建立新項目
        Object.keys(headers).forEach(key => {


            if (item.hasOwnProperty(key)) {
                if (key==='id'&&isPw){
                    newItem[headers[key]] = gUtils.binaryToDecimal(item[key])
                }else {
                    newItem[headers[key]] = item[key]
                }


            } else {
                newItem[headers[key]] = ''
            }
        })

        console.log("newItem",newItem)

        return newItem
    })
   if (isCsv){
       const csvHeaders = newList.length > 0 ? Object.keys(newList[0]) : []

       const csv = Papa.unparse({
           fields: csvHeaders,
           data: newList,
           header: true,
           skipEmptyLines: true
       })

       const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
       saveAs(blob, "sesamebiz.csv")
   }else {
       downloadExcel(newList,pathname)
   }
    // 取得第一項的標題並當成CSV標題


}

const downloadExcel = (data,pathname) => {

    console.log("--------downloadExcel-------------",data,pathname)
    let  processedData={}
    if (pathname==='/cfp/cards'||pathname==='/cards'){
        processedData = data.map(item => ({
            ...item,


            [wordConfig.touchCertEquipment]: Array.isArray(item.認証機器) ? item.認証機器.join(', ') : item.認証機器
        }));
    }else {
        processedData = data.map(item => ({
            ...item,

            [wordConfig.touchRoll]:Array.isArray(item.ロール) ? item.ロール.join(', ') : item.ロール,
            [wordConfig.touchCertEquipment]: Array.isArray(item.認証機器) ? item.認証機器.join(', ') : item.認証機器
        }));
    }

    // 创建工作簿
    const workbook = XLSX.utils.book_new();

    // 创建工作表
    const worksheet = XLSX.utils.json_to_sheet(processedData);

    // 将工作表添加到工作簿
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // 生成 Excel 文件并触发下载
    XLSX.writeFile(workbook, "sesamebiz.xlsx");
};
export const csvUtils={
    downloadCsv,
    downloadLists,
    downloadExcelTemp,
}

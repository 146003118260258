import {csvUtils} from "./csvutils";
import {cfputils} from "./cfputils";
import {gConfig} from "../../chatai/utils/config";



const uuidsToNames = (uuids, alltouchs) => {
    if (!uuids || uuids.length === 0) {
        return [];
    }

    // 使用Set去重uuids数组


    let names = [];
    uuids.forEach(uuid=>{
        let  find=alltouchs.find(item=>item.deviceUUID.toUpperCase()===uuid.toUpperCase())
        if (find){
            names.push(find.deviceName);
        }

    })

    return names;
};

const uuidToName = (uuid, alltouchs) => { //通过设备找到uuids




  let  find= alltouchs.find(item=>item.deviceUUID===uuid.mid)
    if (!find)return  undefined



    return find.deviceName
}
const uuidToNameById = (uuid, alltouchs) => { //通过设备找到uuids




    let  find= alltouchs.find(item=>item.deviceUUID===uuid)
    if (!find)return  uuid



    return find.deviceName
}
const includesIgnoreCase = (str, key) => {
    return typeof str === 'string' && str.toUpperCase().includes(key);
};

const findSearchList = (key, list, tagList) => {

    console.log("搜索设备",key,list,tagList)
    const upperKey = key.toUpperCase(); // Convert key to uppercase once
    return list.filter(item=>{
        if (includesIgnoreCase(item.name, upperKey)) return true;
        if (includesIgnoreCase(item.cardID, upperKey)) return true;
        if (includesIgnoreCase(item.fingerID, upperKey)) return true;
        if (includesIgnoreCase(item.passwordID, upperKey)) return true;
        if (item.uuids && (item.uuids.some(i => includesIgnoreCase(i, upperKey)) || uuidsToNames(item.uuids, tagList).some(i => includesIgnoreCase(i, upperKey)))) return true;
        if (includesIgnoreCase(item.deviceName, upperKey)) return true;
        if (includesIgnoreCase(item.deviceModel, upperKey)) return true;

        if (Array.isArray(item.groupName) && item.groupName.some(i => includesIgnoreCase(i, upperKey))) return true;

        return false;
    });

}


function toCardInfo(id, t, n) {

    console.log("toCardInfo",id,t,n)
    if (!id) {
        return;
    }
    if (!t){
        t=0
    }
    if (typeof id !== 'string') {
        id = String(id); // 确保 id 是字符串
    }
    if (typeof n !== 'string') {
        n = String(n); // 确保 n 是字符串
    }

    let hexString = '';
    hexString += 'F0';
    hexString += t.toString(16).padStart(2, '0').toUpperCase();
    let idLength = id.length / 2; // ID 的长度是十六进制字符数的一半
    hexString += idLength.toString(16).padStart(2, '0').toUpperCase();
    hexString += id.toUpperCase();
    let encoder = new TextEncoder();
    let nameBuffer = encoder.encode(n);
    let nameHex = Array.from(nameBuffer, byte => byte.toString(16).padStart(2, '0')).join('').toUpperCase();
    let nameLength = nameHex.length / 2;
    hexString = hexString.padEnd(38, 'F');
    hexString += nameLength.toString(16).padStart(2, '0').toUpperCase();
    hexString += nameHex;
    hexString = hexString.padEnd(80, '0');
    return hexString;
}


function uniqueByProperty(list, key) {
    const seen = new Set();

    return list.filter(item => {
        const keyValue = item[key];
        if (seen.has(keyValue)) {
            return false;
        }
        seen.add(keyValue);
        return true;
    })
}
function binaryToDecimal(hexString) {
    try {
     return    hexString.split('').map((char, index) => index % 2 !== 0 ? char : '').join('')
    }catch (e) {
        console.log("error",e)
    }


    return hexString;
}
function getBatteryPercent(voltage) {


    const blocks = [5.85, 5.82, 5.79, 5.76, 5.73, 5.70, 5.65, 5.60, 5.55, 5.50, 5.40, 5.20, 5.10, 5.0, 4.8, 4.6];
    const mapping = [100, 95, 90, 85, 80, 70, 60, 50, 40, 32, 21, 13, 10, 7, 3, 0];
    voltage=parseInt(voltage,10)
    voltage=voltage*2/1000

    if (voltage >= blocks[0]) {
        return mapping[0];
    }
    if (voltage <= blocks[blocks.length - 1]) {
        return mapping[mapping.length - 1];
    }
    for (let i = 0; i < blocks.length - 1; i++) {
        const upper = blocks[i];
        const lower = blocks[i + 1];
        if (voltage <= upper && voltage > lower) {
            const value = (voltage - lower) / (upper - lower);
            const max = mapping[i];
            const min = mapping[i + 1];
            return Math.round((max - min) * value + min);
        }
    }
    return 0;

}
function  timeToDate(time){

  //  console.log("timeToDate",time)
    const date = new Date(time * 1000);

    const year = date.getFullYear();

    const month = date.getMonth() + 1;
    const day = date.getDate() ;
    console.log(`${year}年${month}月${day}日`);
      return `${year}年${month}月${day}日`

}


const pageNames={
    members:'ユーザー',
    historys:'全体履歴',
    developer:'開発者向け',
    cards:'カード管理',
    devices:'デバイス（ドア・認証機器）',
    touchDevices:'認証機器',
    ssmDevices:'ドア',
}
const allTags=[pageNames.members, pageNames.devices, pageNames.cards, pageNames.historys, pageNames.developer]

const isWifiModel=(model)=>{
    //deviceModel
    return model==='wm_2'&&model==="hub_3"
}
const isTouchModel=(model)=>{
    return model===gConfig.model.ssm_touch_pro||model===gConfig.model.ssm_touch||model===gConfig.model.sesame_face
}
const isShowType=(model,type)=>{
    switch (type){
        case gConfig.touchType.card:
            return isTouchModel(model)
        case gConfig.touchType.finger:
            return isTouchModel(model)
        case gConfig.touchType.password:
            return model===gConfig.model.ssm_touch_pro
        case gConfig.touchType.face:
            return model===gConfig.model.sesame_face
        case gConfig.touchType.palm:
            return model===gConfig.model.sesame_face
    }

    return false

}
const isLockModel=(model)=>{
    //deviceModel
    return model===gConfig.model.sesame_2||model===gConfig.model.sesame_4||model===gConfig.model.sesame_5||model===gConfig.model.sesame_5_pro||model===gConfig.model.sesame_5_us||model===gConfig.model.bot_2||model===gConfig.model.ssmbot_1
}



const hasListObj = (listA, listB, pc) => {
    return listA.some(itemA => listB.some(itemB => itemB[pc] === itemA[pc]));
}
const hasObj = (listA, listB, pc) => {
    return listA.some(itemA => itemA[pc] === listB[pc]);
}

const readQrcode = (imgUrl, call) => {
   return cfputils.readQrcode(imgUrl,call)
}

function  getStartTimeEndTime(item){

    let  st=item.startTime?item.startTime:item.startAt
    let  et=item.startTime?item.endTime:item.endAt

    let startDate = new Date(st * 1000);
    let endDate = new Date(et * 1000);

    // Format the date and time in the desired format
    // Example format: "2023-03-01 12:00:00"
    let startFormatted = startDate.getFullYear() + '-' +
        ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' +
        ('0' + startDate.getDate()).slice(-2) + ' ' +
        ('0' + startDate.getHours()).slice(-2) + ':' +
        ('0' + startDate.getMinutes()).slice(-2) + ':' +
        ('0' + startDate.getSeconds()).slice(-2);

    let endFormatted = endDate.getFullYear() + '-' +
        ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' +
        ('0' + endDate.getDate()).slice(-2) + ' ' +
        ('0' + endDate.getHours()).slice(-2) + ':' +
        ('0' + endDate.getMinutes()).slice(-2) + ':' +
        ('0' + endDate.getSeconds()).slice(-2);

    return  startFormatted + " - " + endFormatted;
}
const touchPro='touchpro'
function isValidEmail(strEmail) {


    return strEmail.search(/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) !== -1

}
export const gUtils = {
    csvUtils,
    uuidsToNames,
    findSearchList,
    toCardInfo,
    binaryToDecimal,
    uuidToName,
    timeToDate,
    uniqueByProperty,
    allTags,
    pageNames,
    isWifiModel,
    isTouchModel,
    isLockModel,
    touchPro,
    readQrcode,
    hasListObj,
    hasObj,
    getBatteryPercent,
    uuidToNameById,
    getStartTimeEndTime,
    isValidEmail,
    isShowType

}

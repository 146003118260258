exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cards-carddetails-js": () => import("./../../../src/pages/cards/carddetails.js" /* webpackChunkName: "component---src-pages-cards-carddetails-js" */),
  "component---src-pages-cards-index-js": () => import("./../../../src/pages/cards/index.js" /* webpackChunkName: "component---src-pages-cards-index-js" */),
  "component---src-pages-cfp-carddetails-js": () => import("./../../../src/pages/cfp/carddetails.js" /* webpackChunkName: "component---src-pages-cfp-carddetails-js" */),
  "component---src-pages-cfp-cards-js": () => import("./../../../src/pages/cfp/cards.js" /* webpackChunkName: "component---src-pages-cfp-cards-js" */),
  "component---src-pages-cfp-face-js": () => import("./../../../src/pages/cfp/face.js" /* webpackChunkName: "component---src-pages-cfp-face-js" */),
  "component---src-pages-cfp-facedetails-js": () => import("./../../../src/pages/cfp/facedetails.js" /* webpackChunkName: "component---src-pages-cfp-facedetails-js" */),
  "component---src-pages-cfp-fingerdetails-js": () => import("./../../../src/pages/cfp/fingerdetails.js" /* webpackChunkName: "component---src-pages-cfp-fingerdetails-js" */),
  "component---src-pages-cfp-fingers-js": () => import("./../../../src/pages/cfp/fingers.js" /* webpackChunkName: "component---src-pages-cfp-fingers-js" */),
  "component---src-pages-cfp-index-js": () => import("./../../../src/pages/cfp/index.js" /* webpackChunkName: "component---src-pages-cfp-index-js" */),
  "component---src-pages-cfp-palm-js": () => import("./../../../src/pages/cfp/palm.js" /* webpackChunkName: "component---src-pages-cfp-palm-js" */),
  "component---src-pages-cfp-palmdetails-js": () => import("./../../../src/pages/cfp/palmdetails.js" /* webpackChunkName: "component---src-pages-cfp-palmdetails-js" */),
  "component---src-pages-cfp-passworddetails-js": () => import("./../../../src/pages/cfp/passworddetails.js" /* webpackChunkName: "component---src-pages-cfp-passworddetails-js" */),
  "component---src-pages-cfp-passwords-js": () => import("./../../../src/pages/cfp/passwords.js" /* webpackChunkName: "component---src-pages-cfp-passwords-js" */),
  "component---src-pages-cfp-region-js": () => import("./../../../src/pages/cfp/region.js" /* webpackChunkName: "component---src-pages-cfp-region-js" */),
  "component---src-pages-developer-js": () => import("./../../../src/pages/developer.js" /* webpackChunkName: "component---src-pages-developer-js" */),
  "component---src-pages-devices-device-details-js": () => import("./../../../src/pages/devices/deviceDetails.js" /* webpackChunkName: "component---src-pages-devices-device-details-js" */),
  "component---src-pages-devices-group-js": () => import("./../../../src/pages/devices/group.js" /* webpackChunkName: "component---src-pages-devices-group-js" */),
  "component---src-pages-devices-groupdetails-js": () => import("./../../../src/pages/devices/groupdetails.js" /* webpackChunkName: "component---src-pages-devices-groupdetails-js" */),
  "component---src-pages-devices-groupkey-js": () => import("./../../../src/pages/devices/groupkey.js" /* webpackChunkName: "component---src-pages-devices-groupkey-js" */),
  "component---src-pages-devices-index-js": () => import("./../../../src/pages/devices/index.js" /* webpackChunkName: "component---src-pages-devices-index-js" */),
  "component---src-pages-devices-share-js": () => import("./../../../src/pages/devices/share.js" /* webpackChunkName: "component---src-pages-devices-share-js" */),
  "component---src-pages-employees-employees-info-js": () => import("./../../../src/pages/employees/employeesInfo.js" /* webpackChunkName: "component---src-pages-employees-employees-info-js" */),
  "component---src-pages-employees-group-js": () => import("./../../../src/pages/employees/group.js" /* webpackChunkName: "component---src-pages-employees-group-js" */),
  "component---src-pages-employees-index-js": () => import("./../../../src/pages/employees/index.js" /* webpackChunkName: "component---src-pages-employees-index-js" */),
  "component---src-pages-employees-memberdetails-js": () => import("./../../../src/pages/employees/memberdetails.js" /* webpackChunkName: "component---src-pages-employees-memberdetails-js" */),
  "component---src-pages-employees-role-js": () => import("./../../../src/pages/employees/role.js" /* webpackChunkName: "component---src-pages-employees-role-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-price-id-js": () => import("./../../../src/pages/payment/[priceID].js" /* webpackChunkName: "component---src-pages-payment-price-id-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}


import Hider from '../utils/Hider'
import { CircularProgress } from '@mui/material'
import React, { createContext, useContext, useState } from 'react'
import ReactDOM from 'react-dom'

export const GDialogContext = createContext()

export const RootElement = ({ children }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  return (
    <GDialogContext.Provider value={{ isOpenDialog, setIsOpenDialog }}>
      {children}
    </GDialogContext.Provider>
  )
}
export const Dialog = () => {
  const { isOpenDialog } = useContext(GDialogContext)



    // 使用portal将dialog渲染到body中
    return typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
            <Cfploading isPending={isOpenDialog} />,
            document.body,
        );
}
export const Cfploading = ({ isPending }) => {
  return (
    <>
      <Hider show={isPending}>
        <CircularProgress
          sx={{
            position: 'fixed',
            margin: 'auto auto',
            zIndex: '101',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          color="primary"
        />
      </Hider>
    </>
  )
}



import {useEffect, useState} from "react";

import {cfputils, useDialog} from "../components/utils/cfputils";
import {gConfig} from "../chatai/utils/config";

const fingerPath='/touchpro/finger'
export const passwordPath='/touchpro/password'
export const useCfpFpCtrl = (gAuth) => {
    const setIsOpenDialog = useDialog();

    const [devicePasswords, setdevicePasswords] = useState([])
    const [temDevicePasswords, setTemDevicePasswords] = useState([])

    const [deviceFingers, setdeviceFingers] = useState([])
    const [deviceFace, setdeviceFace] = useState([])
    const [devicePalm, setdevicePalm] = useState([])
    const [temDeviceFingers, setTemDeviceFingers] = useState([])

    const [fMmList, setfMmList] = useState([])

    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
            setdevicePasswords([])
            setdeviceFingers([])
            setTemDeviceFingers([])
            setTemDevicePasswords([])
            setfMmList([])

        }
    }, [gAuth.loginState])



    const delFp = (type, deviceID, item) => {
         if (type === 'f') {
            setdeviceFingers(prevState => prevState.filter(obj => !(obj.deviceID===deviceID&&obj.fingerID===item.id)))
            setTemDeviceFingers(prevState => prevState.filter(obj => !(obj.deviceID===deviceID&&obj.fingerID===item.id)))

        } else if (type === 'p') {
            setdevicePasswords(prevState => prevState.filter(obj => !(obj.deviceID===deviceID&&obj.passwordID===item.id)))
            setTemDevicePasswords(prevState => prevState.filter(obj => !(obj.deviceID===deviceID&&obj.passwordID===item.id)))


        }

    }

const updateName=(type, uuid, item)=>{
    if (type === 'f') {
        setdeviceFingers((prevAllCards) => {
            return prevAllCards.map(obj =>
                obj.fingerID === item.id
                    ? { ...obj, name:item.n}
                    : obj
            );
        });
    }else if ( type==='p') {
        setdevicePasswords((prevAllCards) => {
            return prevAllCards.map(obj =>
                obj.passwordID === item.id
                    ? { ...obj, name:item.n}
                    : obj
            );
        });
    }

}
    const addFp = (type, uuid, item) => {
        cfputils.log("添加指纹密码", item, type, uuid)

        if (type === 'f') {

            let  obj={deviceID:uuid,name:item.n,fingerType:item.t,fingerID:item.id}
            if (!deviceFingers.find(i=>i.deviceID===obj.deviceID&&i.fingerID===obj.fingerID)){
                setTemDeviceFingers(prevState => [...prevState,obj])
                setdeviceFingers(prevState => [...prevState,obj])
            }



        } else if (type === 'p') {
            let  obj={deviceID:uuid,name:item.n,passwordType:item.t,passwordID:item.id}
            if (!deviceFingers.find(i=>i.deviceID===obj.deviceID&&i.passwordID===obj.passwordID)){
                setTemDevicePasswords(prevState => [...prevState,obj])
                setdevicePasswords(prevState => [...prevState,obj])
            }


        }

    }
    const updateFp = (type, uuid, item,call) => {

        console.log("准备更新",type,uuid,item)
        if (type==='f'){
            cfputils.makeAll(() => cfputils.apiPost(fingerPath,item ), setIsOpenDialog)
                .then((d) => {
                    setdeviceFingers(prevState =>prevState.map(obj=>obj.fingerID===item.fingerID?item:obj))
                    if (call)call()
                }).catch(e => {
            })
        }else if (type==='p'){
            cfputils.makeAll(() => cfputils.apiPost(passwordPath,item ), setIsOpenDialog)
                .then((d) => {

                    setdevicePasswords(prevState =>prevState.map(obj=>obj.passwordID===item.passwordID?item:obj))
                    if (call)call()
                }).catch(e => {

            })
        }
      }
    const getFp = (type, uuid) => {
        cfputils.log("获取指纹数据", type, uuid)
        if (type==='f'){
            cfputils.makeAll(() => cfputils.apiGet(fingerPath,{ queryStringParameters: {deviceID: uuid}} ), setIsOpenDialog)
                .then((d) => {
                    setdeviceFingers(d.data)
                    cfputils.log("获取指纹数据", d)
                }).catch(e => {
                    console.log("获取指纹数据",e)
                cfputils.err("获取指纹数据", e)
            })
        }
        if (type==='p'){
            cfputils.makeAll(() => cfputils.apiGet(passwordPath, {queryStringParameters:{deviceID:uuid}}), setIsOpenDialog)
                .then((d) => {
                    setdevicePasswords(d.data)
                    cfputils.log("获取密码数据", d)
                }).catch(e => {
                cfputils.err("获取密码数据", e)
            })
        }

    }
    const getMemberFingers = (devices, mmid,call) => {
        if (!mmid)return
        cfputils.log("获取指纹密码社员数据", devices,mmid)
        cfputils.makeAll(() => cfputils.apiGet(fingerPath, {queryStringParameters:{devices:devices, memberID: mmid}}), setIsOpenDialog)
            .then((d) => {
                setfMmList(d.data)
                if (call){
                    call(d.data)
                }
                cfputils.log("获取指纹密码社员数据", d)
            }).catch(e => {
            cfputils.err("获取指纹密码社员数据", e)
        })
    }
    const getMembersFingers = (devices, members,call) => {

        cfputils.log("获取指纹密码社员数据", devices,members)
        cfputils.makeAll(() => cfputils.apiGet(fingerPath, {queryStringParameters:{devices:devices, members: members}}), setIsOpenDialog)
            .then((d) => {

                if (call)call(d)
                cfputils.log("获取指纹密码社员数据", d)
            }).catch(e => {
            cfputils.err("获取指纹密码社员数据", e)
        })
    }

    return {
        updateFp,
        getFp, //获取
        addFp,
            deviceFace,
            devicePalm,
        setdeviceFace,
        setdevicePalm,
        delFp,
        updateName,
        getMemberFingers, //获取社员
        deviceFingers,
        devicePasswords,
        temDeviceFingers,
        setTemDeviceFingers,
        temDevicePasswords,
        setTemDevicePasswords,
        setdevicePasswords,
        setdeviceFingers,
        fMmList,
        getMembersFingers

    }
}

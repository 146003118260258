import {useEffect, useState} from 'react'

import {cfputils, useDialog} from '../components/utils/cfputils'

const path = '/touchpro/binddevice'
export const useCfpBindDevice = (gCfp, ts) => {
    const setIsOpenDialog = useDialog()
    const [mid, setmid] = useState('')
    const [touchDevices, settouchDevices] = useState([])
    const [ssmDevices, setssmDevices] = useState([])
    const [bindData, setBindData] = useState([])


    useEffect(() => {
        setmid(gCfp.mid)
    }, [gCfp.mid])

    useEffect(() => {
        console.log("准备请求", gCfp.cfpDevices, gCfp.isQueryBindDevice)
        if (gCfp.isQueryBindDevice && gCfp.cfpDevices.length > 0) {
            gCfp.setisQueryBindDevice(false)
            let list = gCfp.cfpDevices.map(item => (item.deviceUUID))
            getTouchDevices(list)

        }

    }, [gCfp.isQueryBindDevice])

    useEffect(() => {
        let datasDeviceUUIDs = new Set(touchDevices.map((data) => data.deviceUUID))
        let nItems = gCfp.cfpSsmDevices.filter(
            (item) => !datasDeviceUUIDs.has(item.deviceUUID),
        )
        setssmDevices(nItems)
    }, [gCfp.cfpSsmDevices, touchDevices])
    //

    const getTouchDevice = (touchUUID) => {
        cfputils.log('获取刷卡机', touchUUID)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiPost(path, {mid: mid, op: 'get', touchUUID: touchUUID}),
                setIsOpenDialog,
            )
            .then((d) => {
                settouchDevices(d)
                cfputils.log('获取刷卡机', d)
            })
            .catch((e) => {
                cfputils.err('获取刷卡机', e)
            })
    }
    const getTouchDevices = (touchList) => {
        cfputils.log('获取刷卡机2', touchList)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiPost(path, {mid: mid, op: 'get', list: touchList}),
                setIsOpenDialog,
            )
            .then((touchList) => {
                cfputils.log('获取刷卡机2', touchList)

                gCfp.setCfpDevices(prevState => prevState.map(item => {

                 let data=  touchList.filter(item2=> item.deviceUUID===item2.touchUUID)
                    cfputils.log('获取刷卡机2', data)
                 //   let d = touchList.find(fitem => item.deviceUUID === fitem.uuid)
                    return {...item, bindNames: data }
                }))

            })
            .catch((e) => {
                cfputils.err('获取刷卡机2', e)
            })
    }
    const getBindDevice = (deviceUUID) => {
        cfputils.log('获取绑定刷卡机', deviceUUID)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiPost(path, {
                        mid: mid,
                        op: 'get',
                        deviceUUID: deviceUUID,
                    }),
                setIsOpenDialog,
            )
            .then((res) => {

              let result=        gCfp.cfpDevices.filter(item=>res.some(data=>data.touchUUID===item.deviceUUID))
                setBindData(result)
                cfputils.log('获取绑定刷卡机', res,result)
            })
            .catch((e) => {
                cfputils.err('获取绑定刷卡机', e)
            })
    }
    const addBindDevice = (touchUUID, list) => {
        if (!gCfp.getWifiState(touchUUID)) return
        let size = touchDevices.length + list.length
        if (size > 3) {
            ts({open: true, msg: "最多只能添加三个，无法继续添加"})
            return;
        }
        cfputils.log('添加刷卡机', list, touchUUID)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiPost(path, {
                        mid: mid,
                        op: 'add',
                        list: list,
                        touchUUID: touchUUID,
                    }),
                setIsOpenDialog,
            )
            .then((d) => {
                cfputils.log('添加刷卡机', d)
                settouchDevices((prevState) => [...prevState, ...list])
            })
            .catch((e) => {
                cfputils.err('添加刷卡机', e)
            })
    }
    const delBindDevice = (touchUUID, list) => {
        if (!gCfp.getWifiState(touchUUID)) return

        cfputils.log('删除刷卡机', list, touchUUID)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiPost(path, {
                        mid: mid,
                        op: 'del',
                        list: list,
                        touchUUID: touchUUID,
                    }),
                setIsOpenDialog,
            )
            .then((d) => {
                cfputils.log('删除刷卡机', d)

                settouchDevices(touchDevices.filter(
                    (item) => !list.some((it) => it.deviceUUID === item.deviceUUID),
                ))
            })
            .catch((e) => {
                cfputils.err('删除刷卡机', e)
            })
    }

    return {
        //获取刷卡机绑定的设备
        getBindDevice, //获取设备被绑定
        getTouchDevice, //获取touch绑定的设备
        getTouchDevices, //获取touch绑定的设备
        delBindDevice, //删除
        addBindDevice, //添加
        settouchDevices,
        touchDevices,
        ssmDevices,
        bindData,


    }
}

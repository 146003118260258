import {useState, useEffect} from 'react'
import {API} from 'aws-amplify'
import {cfputils, useDialog} from "../components/utils/cfputils";
import {gConfig} from "../chatai/utils/config";
import {gUtils} from "../components/utils/gutils";

export const useCompanyDevice = (gLoginEmployee, gAuth) => {
    const init = {
        count: 0,
        Items: [],
    }
    const [devices, setDevices] = useState(init)
    const [userDevices, setUserDevices] = useState([])
    const [connectors, setConnectors] = useState([])
    const [selectedDevice, setSelectedDevice] = useState([])
    const [isPending, setIsPending] = useState(false)
    const setIsOpenDialog = useDialog()
    useEffect(() => {
        if (gLoginEmployee.loginInfo.companyID) {

        }
    }, [gLoginEmployee.loginInfo.companyID])

    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
            setDevices(init)
            setConnectors([])
            setSelectedDevice([])
            setUserDevices([])
            setIsPending(false)
        }
    }, [gAuth.loginState])
    const getDeviceHistory = async (deviceId, time = undefined, limit = 50, call) => {
        cfputils.log("getDeviceHistory", deviceId)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/devices/history', {
            op: "get",
            deviceId: deviceId,
            time: time,
            limit: limit
        }), setIsOpenDialog)
            .then((d) => {
                cfputils.log("getDeviceHistory", d)
                if (call) call(d)
            }).catch(e => {
            if (call) call(null, e)
            cfputils.err("getDeviceHistory", e)
        })
    }
    const getAllHistory = (list, call) => {
        if (list.length===0)return

        cfputils.log("getAllHistory", list)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/devices/history', {op: "all", list: list}), setIsOpenDialog)
            .then((d) => {
                cfputils.log("getAllHistory", d)
                if (call) call(d)
            }).catch(e => {
            if (call) call(null, e)
            cfputils.err("getAllHistory", e)
        })
    }
    const getUserDevices = async () => {
        setIsPending(true)
        try {
            const res = await API.get('Attendance', `/user_device`, {
                queryStringParameters: {
                    sub: gAuth.loginSub,
                },
            })
            setUserDevices(res)
            setIsPending(false)
        } catch (err) {
            console.error(err)
        }
    }

    const getDevicesList = (companyID, access) => {
        setIsPending(true)
        API.get('Attendance', `/device`, {
            queryStringParameters: {
                companyID,
                access: JSON.stringify(access),
            },
        })
            .then((res) => {
                setDevices(res)
                setConnectors(
                    res.Items.filter(
                        (i) =>gUtils.isTouchModel(i.deviceModel)
                    ),
                )
                setIsPending(false)
            })
            .catch((err) => err)
    }

    const deleteDevice = (deviceUUID) => {
        API.del('Attendance', `/device`, {
            body: {
                deviceUUID,
                companyID: gLoginEmployee.loginInfo.companyID,
            },
        })
            .then(() => {
                getDevicesList(
                    gLoginEmployee.loginInfo.companyID,
                    gLoginEmployee.loginInfo.access,
                )
            })
            .catch((err) => console.log(err))
    }

    const postDevice = (item) => {
        API.post('Attendance', `/device`, {
            body: {
                ...item,
                companyID: gLoginEmployee.loginInfo.companyID,
            },
        })
            .then(() => {
                getDevicesList(
                    gLoginEmployee.loginInfo.companyID,
                    gLoginEmployee.loginInfo.access,
                )
            })
            .catch((err) => console.log(err))
    }



    return {
        userDevices,
        getUserDevices,
        selectedDevice,
        setSelectedDevice,
        devices,
        setDevices,
        getDevicesList,
        isPending,
        setIsPending,
        connectors,
        deleteDevice,
        postDevice,
        setConnectors,
        getDeviceHistory,
        getAllHistory,
        setIsOpenDialog
    }
}




export const wordConfig={

    touchDeviceName:'ユーザー名',
    touchCertEquipment:'認証機器',
    touchIndex:'インデックス',
    touchPinNumber:'暗証番号',
    touchRoll:'roll'

}

import {useEffect, useState} from 'react'
import {cfputils, useDialog} from '../components/utils/cfputils'
import {gConfig} from "../chatai/utils/config";


const path = '/touchpro/group/member'

export const useMemberDevice = (gAuth,gStripe) => {
    const setIsOpenDialog = useDialog()
    const [list, setList] = useState([])

    useEffect(() => {
        if (gStripe.customer.id) {
            getMemberDevices()
        }

    }, [gStripe.customer.id])

    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
            setList([])
        }
    }, [gAuth.loginState])
    const getMemberDevices = () => {
        cfputils.log("获取成员设备")
        let  cid=gStripe.customer.id
        cfputils.makeAll(() => cfputils.apiPost(path, {op: "gets", cid: cid}), setIsOpenDialog)
            .then((d) => {
                setList(d)
                cfputils.log("获取成员设备", d)
            }).catch(e => {
            cfputils.err("获取成员设备", e)
        })
    }
    const findMemberGroupName = (mid) => {
        console.log("findMemberGroupName", mid, list);

        // Find the item in the list with the matching gid
        const item = list.find(item => item.gid === mid);

        // Return the item's name if found, or an empty string if not found
        return item ? item.name : mid;
    }

    const addMemberDevice = (name, devices,call) => {

        let  cid=gStripe.customer.id
        cfputils.log("添加组设备", cid)
        cfputils.makeAll(() => cfputils.apiPost(path, {
            op: "add", obj: {name: name, cid: cid, uuids: devices}
        }), setIsOpenDialog)
            .then((d) => {
                setList(prevState => [...prevState,d])

                if (call)call()
                cfputils.log("添加组设备", d)
            }).catch(e => {
            cfputils.err("添加组设备", e)
        })
    }
    const delsMemberDevice = ( objs,call) => {
        let  cid=gStripe.customer.id
        const objsWithCid = objs.map(obj => ({
            ...obj, // 复制原有属性
            cid: cid // 添加 cid 属性
        }));
        cfputils.log("删除组设备", objsWithCid)
        cfputils.makeAll(() => cfputils.apiPost(path, {
            op: "dels", objs: objsWithCid
        }), setIsOpenDialog)
            .then((d) => {
                setList(prevState => prevState.filter(item => !objs.some(obj => obj.gid === item.gid)));

                if (call)   call(objs)


                cfputils.log("删除组设备", d)
            }).catch(e => {
            cfputils.err("删除组设备", e)
        })

    }
    const updateMemberDevice = ( obj,call) => {
        let  cid=gStripe.customer.id
        cfputils.log("更新组设备", cid)
        cfputils.makeAll(() => cfputils.apiPost(path, {
            op: "update", obj: obj
        }), setIsOpenDialog)
            .then((d) => {
                if (obj.name){
                    setList(prevState => prevState.map(item => item.gid === obj.gid ? { ...item, name: obj.name } : item))
                }else {
                    setList(prevState => prevState.map(item => item.gid === obj.gid ? { ...item, uuids: obj.uuids } : item))
                }
                cfputils.log("更新组设备", d)
                if (call)call(d)

            }).catch(e => {
            cfputils.err("更新组设备", e)
        })

    }
    const addShareDevices = ( data,call) => {

        cfputils.log("添加分享组设备", data)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/binding/memberdevice', {
      ...data
        }), setIsOpenDialog)
            .then((d) => {
                cfputils.log("添加分享组设备reult", d)
                if (call)call(d)

            }).catch(e => {
            cfputils.err("添加分享组设备", e)
        })

    }
    return {
         addMemberDevice, delsMemberDevice, updateMemberDevice,list,addShareDevices,findMemberGroupName
    }
}

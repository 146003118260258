import React, { useContext } from 'react'
import { Modal, Box, Typography } from '@mui/material'
import { GlobalStateContext } from '../../context/GlobalContextProvider'

export default function ({ open, onClose, children, title }) {
  const { modalTitle } = useContext(GlobalStateContext)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '550px',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    padding: 4,
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        <Typography sx={{ fontSize: '18px !important', fontWeight: 'Bold' }}>
          {modalTitle}
          {title}
        </Typography>
        {children}
      </Box>
    </Modal>
  )
}

import { navigate } from "gatsby"

export const useGfun = () => {
  const colors = {
    c28AEB1: "#28AEB1",
  }

  const styles = {
    v: { display: "flex", flexDirection: "column" },
    h: { display: "flex", flexDirection: "row", alignItems: "center" },
    hc: {},
  }
  const fonts = {
    fontCfpCards: {
      title: "新規カード追加",
      // h1: "1.請先把IC卡讀取刷卡機 ",
      // h1t: "",
      h2: "カード読み取り",
      h2b: "取得",
      b2t: "＋ボタンを押しタッチ/タッチプロにカードをかざして下さい。",
      listTitle: "のカード一覧",
    },
    fontCfpFingers: {
      title: "新規指紋追加",
      // h1: "1.請先把指紋讀取刷卡機",
      // h1t: "",
      h2: "指紋読み取り",
      h2b: "取得",
      b2t: "＋ボタンを押しタッチ/タッチプロに指紋をかざして下さい。",
      listTitle: "の指紋一覧",
    },
    fontFace: {
      title: "新規人脸追加",
      // h1: "1.請先把指紋讀取刷卡機",
      // h1t: "",
      h2: "指紋読み取り",
      h2b: "取得",
      b2t: "＋ボタンを押しタッチ/タッチプロに人脸をかざして下さい。",
      listTitle: "の人脸一覧",
    },
    fontPalm: {
      title: "新規手掌追加",
      // h1: "1.請先把指紋讀取刷卡機",
      // h1t: "",
      h2: "指紋読み取り",
      h2b: "取得",
      b2t: "＋ボタンを押しタッチ/タッチプロに手掌をかざして下さい。",
      listTitle: "の手掌一覧",
    },
    fontCfpPw: {
      title: "新規暗証番号追加",
      // h1: "1.請先打密碼",
      // h1t: "",
      h2: "暗証番号入力",
      h2b: "取得",
      b2t: "＋ボタンを押しタッチプロに暗証番号を入力し、解錠ボタンを押してください。",
      listTitle: "の暗証番号一覧",
    },
    fontCfpAllCards: {
      title: "新規カード追加",
      // h1: "1.先選刷卡機 全體卡片一覽",
      // h1t: "",
      h2: "カード読み取り",
      h2b: "取得",
      b2t: "＋ボタンを押しタッチ/タッチプロにカードをかざして下さい。",
      listTitle: "カード管理一覧",
    },
  }

  const goBack = () => {
    navigate(-1)
  }

  return {
    goBack,
    colors,
    styles,
    fonts,
  }
}

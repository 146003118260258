import {useEffect, useState} from 'react'
import {Auth, Hub} from 'aws-amplify'

import {cfputils, useDialog} from "../components/utils/cfputils";
import {gConfig} from "../chatai/utils/config";

export const useAuthState = () => {
    const [loginMail, setLoginMail] = useState('')
    const [loginState, setLoginState] = useState(gConfig.loginState.configured)
    const [loginSub, setLoginSub] = useState('')
    const [nickname, setNickname] = useState('')
    const [companiesList, setCompaniesList] = useState([])
    const [isLoginState, setIsLoginState] = useState(true)
    const [isClearData, setIsClearData] = useState(false)
    const setIsOpenDialog = useDialog();

    const getCompanies = () => {
        /*    API.get('Attendance', `/`)
              .then((res) => {
                console.log("getCompanies",res)
                setCompaniesList(res)
              })
              .catch((err) => console.log(err))*/
        cfputils.log("获取所有公司企业")
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/ctrl', {op: 'get'}), setIsOpenDialog)
            .then((d) => {
                cfputils.log("获取所有公司企业", d)
                setCompaniesList(d)

            }).catch(e => {

            cfputils.err("获取所有公司企业", e)
        })

    }


    const addCompany = (name, email, call) => {
      //  console.log('添加公司', name, email)
        cfputils.makeAll(() => cfputils.apiPost('/touchpro/company/ctrl', {
            op: 'add',
            name: name,
            employeeEmail: email
        }), setIsOpenDialog)
            .then((d) => {
                cfputils.log("添加公司", d)
                setIsClearData(true)
                setCompaniesList(prevState => [...prevState, d])
                if (call) call()
            }).catch(e => {
            if (call) call()


            cfputils.err("添加公司", e)
        })
    }
    // 監控登入，方便除錯，並且抓取登入中的mail用
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                if (user) {
             //       console.log("当前用户", user)
                    setLoginMail(user.username)
                    setNickname(user.attributes.nickname)
                    setLoginSub(user.attributes.sub) //存取入退室系統的員工subUUID
                    setLoginState(gConfig.loginState.loginIn)
                    // getCompanies()
                } else {
                    setLoginState(gConfig.loginState.loginOut)
                    setCompaniesList([])
                }
            })
            .catch(() => {
                setLoginState(gConfig.loginState.loginOut)
            })
    }, [loginState])

    useEffect(() => {
        Hub.listen('auth', (data) => {
            // console.log('Hub', data.payload.event)
            setLoginState(data.payload.event)
        })

    }, [])
    useEffect(() => {

      //  console.log("获取登录状态", loginState)

        if (loginState === 'signOut') {
            setIsLoginState(false)
            setCompaniesList([])
        } else {
            setIsLoginState(true)
        }
    }, [loginState])

    return {
        loginMail,
        setLoginMail,
        loginState,
        companiesList,
        setCompaniesList,
        getCompanies,
        nickname,
        loginSub,
        isLoginState,
        setIsLoginState,
        isClearData,
        addCompany
    }
}

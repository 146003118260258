import awsmobile from './aws-exports'
import Amplify from 'aws-amplify'
import GlobalContextProvider from './src/context/GlobalContextProvider'
import React from 'react'
import { ThemeProvider } from '@mui/material'
import theme from './src/theme/theme'
import CssBaseline from '@mui/material/CssBaseline'
import './src/styles/global.css'
import {Dialog,  RootElement } from "./src/components/cfp/cfploading";
import {navigate} from "gatsby";
import {BannerContext, BannerCtrl, CmBanner} from "./src/components/banner";
Amplify.configure(awsmobile)

export const onRouteUpdate = ({ location,prevLocation  }) => {
  const navEntries = performance.getEntriesByType("navigation");

  console.log("开始刷新页面",location,prevLocation)
  if (navEntries.length > 0 && navEntries[0].type === 'reload') {
  //  console.log('当前刷新路径', location.pathname);
    if (location.pathname === '/devices/groupdetails/') {
      navigate('/devices/group');
    }
  }

  if (location.pathname==='/cfp/region/'){
     if (!prevLocation){
       navigate('/');
     }
  }
};

export const wrapRootElement = ({ element }) => {




  return (



   <BannerCtrl  >




      <RootElement>

    <GlobalContextProvider>

      <ThemeProvider theme={theme}>


        <CssBaseline />
    {/*  <GChatProvider>*/}

        {element}
     {/* </GChatProvider>*/}
              <Dialog />



      </ThemeProvider>

    </GlobalContextProvider>
      </RootElement>
   </BannerCtrl>
  )
}

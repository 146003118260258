import {API, Auth,  PubSub} from "aws-amplify";
import {AWSIoTProvider} from "@aws-amplify/pubsub";
import {useState} from "react";
import AWS from 'aws-sdk';
import {Buffer} from "buffer"
import {Cmac} from "../chatai/utils/cmac";


function isLock(hex) {

    try {
        let bytes = [];
        for (let i = 0; i < hex.length - 1; i += 2) {
            bytes.push(parseInt(hex.substr(i, 2), 16));
        }
        let bytes2 = new Uint8Array(bytes);
        let c = bytes2[bytes2.length - 1]
        return (c & 2) > 0 ? true : false
    } catch (e) {
    }
    return false
}

function getBatteryPercent(voltage) {
    const blocks = [5.85, 5.82, 5.79, 5.76, 5.73, 5.70, 5.65, 5.60, 5.55, 5.50, 5.40, 5.20, 5.10, 5.0, 4.8, 4.6];
    const mapping = [100, 95, 90, 85, 80, 70, 60, 50, 40, 32, 21, 13, 10, 7, 3, 0];

    if (voltage >= blocks[0]) {
        return mapping[0];
    }
    if (voltage <= blocks[blocks.length - 1]) {
        return mapping[mapping.length - 1];
    }
    for (let i = 0; i < blocks.length - 1; i++) {
        const upper = blocks[i];
        const lower = blocks[i + 1];
        if (voltage <= upper && voltage > lower) {
            const value = (voltage - lower) / (upper - lower);
            const max = mapping[i];
            const min = mapping[i + 1];
            return Math.round((max - min) * value + min);
        }
    }
    return 0;

}

function getBatteryPercentByMeset(hexString) {
    try {
        // 检查输入是否有效
        if (hexString.length < 4) {
            return -1
        }
        // 提取前4个字符作为十六进制数字
        const hex = hexString.slice(0, 4);
        // 将十六进制字符串转换为字节数组
        const bytes = new Uint8Array(hex.match(/[\da-f]{2}/gi).map(h => parseInt(h, 16)));
        // 创建一个与字节数组等长的ArrayBuffer
        const buffer = new ArrayBuffer(2);
        const view = new DataView(buffer);
        // 设置字节（注意，DataView是大端序默认，所以我们需要指定小端序）
        view.setUint8(0, bytes[0]);
        view.setUint8(1, bytes[1]);
        let batteryData = view.getInt16(0, true)
        let batteryVoltage = batteryData * 7.2 / 1023 //5.834604105571848
        return getBatteryPercent(batteryVoltage)
    } catch (e) {
        console.log(e)
    }


    // 读取小端序的16位整数
    return -1
}




function callData(call, shadowData) {
    var mlock = isLock(shadowData.mechst)

    let isContails = false

    for (let keyValue of Object.entries(shadowData.wm2s ? shadowData.wm2s : {})) {

        if (keyValue[1] === '01') {
            isContails = true
        }
    }


   // console.log("当前shadowData", shadowData, getBatteryPercentByMeset(shadowData.mechst))
    if (call) call(isContails ? mlock : null, getBatteryPercentByMeset(shadowData.mechst))

}


export const useIotCtrl = (gAuth) => {
    const [subscribes, setsubscribes] = useState([])
    const [isInit, setisInit] = useState(false)
    const [isAuth, setisAuth] = useState(false)


    const initIot = () => {

    //    console.log("iotlog", "isinit", isInit, isAuth)

        if (!isInit) {

            PubSub.addPluggable(
                new AWSIoTProvider({
                    aws_pubsub_region: 'ap-northeast-1',
                    aws_pubsub_endpoint: 'wss://a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com/mqtt'
                })
            )
            setisInit(true)

        }


    }
    const sub = (divcesId, call) => {
        initIot()
        if (!isAuth) {
            Auth.currentCredentials().then((info) => {
                API.post('Attendance', `/device/addpolicy`, {
                    body: {
                        id: info.identityId
                    }
                })
                    .then((res) => {
                        setisAuth(true)
                        subrestart(divcesId, call)
                    })
                    .catch((err) => {
                    })
            });

        } else {
            subrestart(divcesId, call)
        }

    }
    const subrestart = (divcesId, call) => {
        let topic = `$aws/things/sesame2/shadow/name/${divcesId}/update/accepted`
        const sub = PubSub.subscribe(topic, {provider: 'AWSIoTProvider'}).subscribe({
            next: data => {

                callData(call, data.value.state.reported)
                //    if (call) call(isLock(data.value.state.reported.mechst))
            },
            error: error => console.error('subrestart', 'Error during subscription', error),
            complete: () => console.log('subrestart', "complete"),
        });
        setsubscribes(prevSubscribes => [...prevSubscribes, sub]);
    }
    const unsub = () => {
        subscribes.forEach(sub => {
            sub.unsubscribe(); // 或者你的取消订阅的方法
        });

        setsubscribes([]);
    }
    const mIot = () => {
        const identityPoolId = 'ap-northeast-1:0a1820f1-dbb3-4bca-9227-2a92f6abf0ae';
        AWS.config.region = 'ap-northeast-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
        });
        return new AWS.IotData({endpoint: 'a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com'})
    }
    const getShadow = (deviceId, call) => {
        if (!deviceId) return
        const thingName = 'sesame2';
        const shadowName = deviceId.toUpperCase(); // Assuming this is the equivalent of ssm2.deviceId.toString().uppercase()
        mIot().getThingShadow({thingName: thingName, shadowName: shadowName}, function (err, data) {
            if (data) {
                const shadowData = JSON.parse(data.payload)['state']['reported'];

                let connectedWM2KeyValue;
            //    console.log("设备影子信息", deviceId, shadowData, JSON.parse(data.payload))

                for (let keyValue of Object.entries(shadowData.wm2s ? shadowData.wm2s : {})) {
                    if (keyValue[1] === '01') {
                        connectedWM2KeyValue = keyValue;
                        if (call) call(shadowName, connectedWM2KeyValue)
                    }
                }
            }
        });
    }
    const getShadowTime = (deviceId, call) => {
        if (!deviceId) return
        const thingName = 'sesame2';
        const shadowName = deviceId.toUpperCase(); // Assuming this is the equivalent of ssm2.deviceId.toString().uppercase()
        mIot().getThingShadow({thingName: thingName, shadowName: shadowName}, function (err, data) {
            if (data) {
                const shadowData = JSON.parse(data.payload)['state']['reported'];

                let connectedWM2KeyValue;
             //   console.log("设备影子信息", deviceId, shadowData, JSON.parse(data.payload))

                for (let keyValue of Object.entries(shadowData.wm2s ? shadowData.wm2s : {})) {
                    if (keyValue[1] === '01') {
                        connectedWM2KeyValue = keyValue;
                        if (call) call(shadowName, connectedWM2KeyValue)
                    }
                }
            }
        });
    }

    const getShadowSatus = (deviceId, call) => {
        const thingName = 'sesame2';
        const shadowName = deviceId.toUpperCase(); // Assuming this is the equivalent of ssm2.deviceId.toString().uppercase()
        mIot().getThingShadow({thingName: thingName, shadowName: shadowName}, function (err, data) {
            if (err) {
            } else {
                const shadowData = JSON.parse(data.payload)['state']['reported'];


                callData(call, shadowData)

            }
        });
    }
    const getShadowWifiBattery = async (deviceId) => {

        try {
            const thingName = 'sesame2';
            const shadowName = deviceId.toUpperCase(); // Assuming this is the equivalent of ssm2.deviceId.toString().uppercase()
            const getThingShadowPromise = (params) => {
                return new Promise((resolve, reject) => {
                    mIot().getThingShadow(params, (err, data) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    });
                });
            };
            let result = await getThingShadowPromise({
                thingName: thingName,
                shadowName: shadowName
            })
            let shadowData = JSON.parse(result.payload)['state']['reported']
            let shadowData2 = JSON.parse(result.payload)['metadata']['reported']
            let lock = undefined
            for (let keyValue of Object.entries(shadowData.wm2s ? shadowData.wm2s : {})) {
                if (keyValue[1] === '01') {
                    lock = isLock(shadowData.mechst);

                }
            }


          //  console.log("测试设备信息", deviceId, getBatteryPercentByMeset(shadowData.mechst), lock, shadowData2.mechst.timestamp)

            return {
                battery: getBatteryPercentByMeset(shadowData.mechst),
                lock: lock,
                ts: shadowData2.mechst.timestamp || ''
            }

        } catch (e) {
         //   console.log("e", e)
        }

        return {battery: '', lock: undefined}
    }
    const changeSwitch = async (deviceId, sescretKey,code=88) => {
        const sign = await Cmac.cmacTime(sescretKey)
        getShadow(deviceId, (shadowName, data) => {
            const cmdData = new Uint8Array(1);
            cmdData[0] = code;
            let signData = Buffer.from(sign, 'hex').slice(0, 4);
            let historyTagData = history_tag_parser();
            let payload = new Uint8Array(signData.length + cmdData.length + shadowName.length + historyTagData.length);
            payload.set(signData);
            payload.set(cmdData, signData.length);
            payload.set(new TextEncoder().encode(shadowName), signData.length + cmdData.length);
            payload.set(historyTagData, signData.length + cmdData.length + shadowName.length);
            const params = {
                topic: "wm2".concat(data[0], "cmd"),
                payload: payload,
                qos: 0
            };
            mIot().publish(params, function (err, data) {
            });
        });
    }
    const Sesame2HistoryLockOpType = {
        "BLE": 0,
        "WM2": 1,
        "WEB": 2,
        "BASE": 30
    };
    const history_tag_parser = function () {
        const MAX_EMAIL_BYTES = 21; // 最大允许的邮箱字节数
        const TOTAL_BYTES = MAX_EMAIL_BYTES + 1; // 总字节数（包括第一个字节）
        try {
            let username = gAuth.loginMail;
            if (gAuth.loginMail.includes('@')) {
                username = gAuth.loginMail.split('@')[0];
            }

            let encoder = new TextEncoder();
            let emailBytes = encoder.encode(username);

            // 如果邮箱字节数超出限制，进行截断
            if (emailBytes.length > MAX_EMAIL_BYTES) {
                emailBytes = emailBytes.slice(0, MAX_EMAIL_BYTES);
                console.warn("Email address truncated due to length limit");
            }

            let resourceCode = Sesame2HistoryLockOpType.BASE * Sesame2HistoryLockOpType.WEB;
            let history_first_int8 = resourceCode + emailBytes.length;

            const result = new Uint8Array(TOTAL_BYTES);
            result[0] = history_first_int8;
            result.set(emailBytes, 1);

            return result;
        } catch (error) {
            console.error("Error in history_tag_parser:", error);
            return new Uint8Array(TOTAL_BYTES); // 返回空数组作为fallback
        }
    };


    return {
        sub,
        unsub,
        getShadowSatus,
        changeSwitch,
        getShadowTime,
        getShadowWifiBattery

    }
}

import {API} from "aws-amplify";
import {useContext} from "react";
import {GDialogContext} from "../cfp/cfploading";
import {Decoder} from "@nuintun/qrcode";
import buffer, {Buffer} from "buffer";
import {gConfig} from "../../chatai/utils/config";

export const useDialog = () => {
    const {setIsOpenDialog} = useContext(GDialogContext);
    return setIsOpenDialog;
}



const uuidsToName = (cards, names) => {
    if (!cards) {
        console.error('cards is undefined');
        return;
    }

    cards.forEach(card => {
        if (!card.uuids) {
            console.error(`uuids is undefined for card`);
            return;
        }

        let updatedUuids = [];
        let uuidsName = [];
            card.uuids.map(uuid => {

            const found = names.find(name => name.deviceUUID === uuid);

            if (found){
                updatedUuids.push(uuid)
                uuidsName.push(found.deviceName)
            }

            // 如果没有找到name，这个map的返回值将不会被使用
        }); // 过滤掉那些未找到name的情况

        card.uuids = updatedUuids; // 更新card的uuids为只包含找到name的uuids
        card.uuidsName = uuidsName; // 更新card的uuidsName为找到的names
    });

    return cards;
};



const apiPost = (path, body) => {
    return new Promise((resolve, reject) => {
        API.post('Attendance', path, {body})
            .then((res) => {
                if (path === '/device') {
                    resolve(res);
                } else {
                    if (res.code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}
const apiPostHeader = (path, body,headers) => {
    return new Promise((resolve, reject) => {
        API.post('Attendance', path, {headers:headers,body:body})
            .then((res) => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    reject(res.message);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}
const apiGet = (path, body) => {
    return new Promise((resolve, reject) => {
        API.get('Attendance', path, body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
const apiPut = (path, body) => {
    return new Promise((resolve, reject) => {
        API.put('Attendance', path, body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

const apiDel = (path, body) => {

    return new Promise((resolve, reject) => {
        API.del('Attendance', path, {body})
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });

}
const apiPatch = (path, body) => {

    return new Promise((resolve, reject) => {
        API.patch('Attendance', path, {body})
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });

}

const isDebug = true

function log(...args) {
    if (isDebug)
        console.log("日志", ...args)

}

function err(...args) {
    console.error("日志", ...args)

}

const makeAll = async (mfun, setIsOpenDialog) => {

    if (setIsOpenDialog) setIsOpenDialog(true)
    try {
        return await mfun()
    } catch (error) {
        cfputils.err("makeAll 错误", error)
        if (setIsOpenDialog) setIsOpenDialog(false)
        throw error // 重新抛出错误，以便它可以被外部 catch 块捕获
    } finally {
        if (setIsOpenDialog) setIsOpenDialog(false)
    }
}
const isModelLock = (deviceModel) => {
    if (deviceModel === 'sesame_5' || deviceModel === 'sesame_4' || deviceModel === 'sesame_5_pro' || deviceModel === 'sesame_2') {

        return true
    }

    return false

}
const cfpUpdate = (datas, update) => {

    let found = false;

    let c = datas.map((item) => {
        if (item.id === update.id) {
            found = true;
            if (update.n) {
                item.n = update.n
            }
            if (update.mm) {
                item.mm = update.mm
                item.mmid = update.mmid
            }
        }
        return item
    })
    if (!found) {
        c = c.concat(update);
    }
    return c
}

function timestampToTime(timestamp) {

    if (!timestamp){
        return ''
    }
  try {
      let timestampStr = timestamp.toString();

      let date
      if (timestampStr.length===10){
          date = new Date(parseInt(timestamp) * 1000)
      }else {
          date=new Date(parseInt(timestamp))
      }
      // 将时间戳转换为Date对象


      let year = date.getFullYear()

      // JavaScript的getMonth方法返回0-11，所以需要+1
      let month = ('0' + (date.getMonth() + 1)).slice(-2)

      let day = ('0' + date.getDate()).slice(-2)

      // 获取小时数，如果小于12则为'午前'，否则为'午后'
      let hours = date.getHours()
      let meridiem = hours < 12 ? '午前' : '午後'
      hours = hours % 12
      hours = hours ? hours : 12 // 0小时转换为12

      let minutes = ('0' + date.getMinutes()).slice(-2)

      let seconds = ('0' + date.getSeconds()).slice(-2)

      return (
          year +
          '年' +
          month +
          '月' +
          day +
          '日 ' +
          meridiem +
          hours +
          ':' +
          minutes +
          ':' +
          seconds
      )
  }catch (e){

  }
   return ''
}

const hasListObj = (listA, listB, pc) => {
    return listA.some(itemA => listB.some(itemB => itemB[pc] === itemA[pc]));
}
const hasObj = (listA, listB, pc) => {
    return listA.some(itemA => itemA[pc] === listB[pc]);
}

const isTouch = (model) => {
    if (modelName[9] === model || modelName[10] === model) {

        return true
    }

    return false;
}
const modelName = ['sesame_2', 'wm_2', 'ssmbot_1', 'bike_1', 'sesame_4', 'sesame_5', 'bike_2', 'sesame_5_pro', 'sesame_face','open_sensor_1', 'ssm_touch_pro', 'ssm_touch', 'BLE_Connector_1', '', '', 'ssm_a1', 'ssm_c1',gConfig.model.bot_2]

const isOs3 = (productType) => {

    if (   modelName[productType] === 'sesame_5'
        || modelName[productType] === 'wm_2'
        || modelName[productType] === 'ssm_touch_pro'
        || modelName[productType] === 'ssm_touch'
        || modelName[productType] === 'sesame_5_pro'
        || modelName[productType] === 'sesame_face'
        || modelName[productType] === gConfig.model.bot_2

    ) {
        return true
    }

    return false
}
const readQrcode = (imgUrl, call) => {
    if (!imgUrl) return

    new Decoder()
        .scan(URL.createObjectURL(imgUrl))
        .then((result) => {
            const urlParams = new URLSearchParams(result.data)
            const sk = urlParams.get('sk').replace(/ /g, '+')
            const msk = Buffer.from(sk, 'base64')
            const data = buffer.Buffer.from(msk, 'hex');
            console.log("数据data", data)
            const productType = parseInt(data.slice(0, 1).toString('hex'), 16) //1
            console.log("数据dataOs3", productType)
            if (isOs3(productType)) {
                const secretKey = data.slice(1, 1 + 16).toString('hex');//1-17
                const sesame2PublicKey = data.slice(1 + 16, 1 + 16 + 4).toString('hex');
                const keyIndex = data.slice(1 + 16 + 4, 1 + 16 + 4 + 2).toString('hex');
                let deviceUUID = data.slice(1 + 16 + 4 + 2).toString('hex');
                deviceUUID = deviceUUID.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');
                const qrKeyInfo = {
                    secretKey: secretKey,
                    keyIndex: keyIndex,
                    sesame2PublicKey: sesame2PublicKey,
                    keyLevel: parseInt(urlParams.get('l')),
                    deviceModel: modelName[productType],
                    deviceName: urlParams.get('n'),
                    deviceUUID: deviceUUID.toUpperCase(),
                }
                console.log("数据dataOs3", qrKeyInfo)
                call(null, qrKeyInfo)
            } else {
                const secretKey = data.slice(1, 17).toString('hex');//1-17
                const sesame2PublicKey = data.slice(17, 81).toString('hex');
                const keyIndex = data.slice(81, 83).toString('hex');
                let deviceUUID = data.slice(83, 99).toString('hex');
                deviceUUID = deviceUUID.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');
                const qrKeyInfo = {
                    secretKey: secretKey,
                    keyIndex: keyIndex,
                    sesame2PublicKey: sesame2PublicKey,
                    keyLevel: parseInt(urlParams.get('l')),
                    deviceModel: modelName[productType],
                    deviceName: urlParams.get('n'),
                    deviceUUID: deviceUUID.toUpperCase(),
                }
                console.log("数据data", qrKeyInfo)
                call(null, qrKeyInfo)
            }
        })
        .catch((error) => {
            console.error("数据data", error)
            call(error)
        })
}
export const cfputils = {
    log,
    err,
    uuidsToName,
    apiPost,
    apiGet,
    apiPut,
    makeAll,
    apiDel,
    apiPatch,
    cfpUpdate,
    timestampToTime,
    readQrcode,
    hasListObj,
    isTouch,
    hasObj,
    isModelLock,
    apiPostHeader
}

let envList = {
    test: 'test',
    biz: 'biz'
}
let stripKey = {
    test: 'pk_test_lXQvIVyg33i6wCEagbMGIICd',
    biz: 'pk_live_SAO5zmCNp1HfQxRkvfLU7YwS'
}
let awsUrl = {
    test: 'https://bbemppkf6c.execute-api.ap-northeast-1.amazonaws.com/test',
    biz: 'https://bbemppkf6c.execute-api.ap-northeast-1.amazonaws.com/biz',

}

let env = envList.biz
const getConfigStripKey = () => {
    return env === envList.test ? stripKey.test : stripKey.biz
}
const getConfigAwsUrl = () => {
    return env === envList.test ? awsUrl.test : awsUrl.biz
}
export const envConfig = {
    getConfigStripKey,
    getConfigAwsUrl


}

import {useEffect, useState} from 'react'
import {cfputils, useDialog} from '../components/utils/cfputils'
import {gUtils} from "../components/utils/gutils";
import AWS from "aws-sdk";
import {gConfig} from "../chatai/utils/config";

const pathDevice = '/device'
export const useCfpCtrl = (
    gAuth,
    gLoginEmployee,
    gCompanyEmployee,
    gStripe,
    ts,
) => {
    /*选择用户的设备*/
    const setIsOpenDialog = useDialog()
    const [cfpSsmDevices, setcfpSsmDevices] = useState([]) //用户ssm设备

    const [cfpDevices, setCfpDevices] = useState([]) //用户touch设备
    const [cfpChooseDevices, setCfpChooseDevices] = useState([]) //用户ssm设备
    const [allDevices, setAllDevices] = useState([]) //用户ssm设备
    const [listMm, setlistMm] = useState([])
    const [mid, setmid] = useState([])
    const [touchId, setTouchId] = useState(undefined)
    const [isQueryBindDevice, setisQueryBindDevice] = useState(false)
    const [tempMid,setTempMid]=useState([])
    useEffect(() => {
        if (gAuth.loginState === gConfig.loginState.loginOut) {
            setcfpSsmDevices([])
            setCfpDevices([])
            setCfpChooseDevices([])
            setAllDevices([])
            setlistMm([])
            setTouchId(undefined)
            setTempMid([])
            setisQueryBindDevice(false)
        }
    }, [gAuth.loginState])
    useEffect(() => {
   console.log("asdsadadadaxzc",allDevices.filter((item) => {
       if (gUtils.isTouchModel(item.deviceModel)){
           return !cfpDevices.some((it) => it.deviceUUID === item.deviceUUID)
       }
       return false
   }))



        setCfpChooseDevices(allDevices.filter((item) => {
            if (gUtils.isTouchModel(item.deviceModel)){
                return !cfpDevices.some((it) => it.deviceUUID === item.deviceUUID)
            }
            return false
        }))
    }, [allDevices,cfpDevices])
    useEffect(() => {
        cfputils.log('获取企业社员', gCompanyEmployee.employees)
        setlistMm(gCompanyEmployee.employees?.Items||[])
    }, [gCompanyEmployee.employees])


    useEffect(async () => {
        console.log("准备请求设备",gStripe.customer.id)
        if (gStripe.customer.id) {
            setmid(gStripe.customer.id)  //gStripe.customer.id
         await  getUserDevices()


        }
    }, [gStripe.customer.id])
    const uuidByDevices=(ids)=>{
        console.log("uuidByDevices",ids,cfpSsmDevices)
        if (!ids)return []
        let  g=[]
          ids.forEach(item=>{
              let  d=   cfpSsmDevices.find(obj=>obj.deviceUUID ===item)
              if (d){
                  g.push(d)
              }
          })
        return  g
    }

    const findDeviceUUIDName = (value) => {

        console.log("findDeviceUUIDName",cfpDevices,value)
        const device = cfpDevices.find(item => item.deviceUUID === value);
        return device ? device.deviceName : value;
    };

    const uuidByMm=(ids)=>{
        console.log("uuidByDevices",ids)
        if (!ids)return []

        let  g=[]
        ids.forEach(item=>{
            let  d=   listMm.find(obj=>obj.subUUID ===item)
            if (d){
                g.push(d)
            }
        })
        return  g
    }

    const getWifiState = (touchId) => {
        cfputils.log('设备状态', cfpDevices.length, touchId, cfpDevices)
        let c = cfpDevices.find((item) => item.deviceUUID === touchId)?.wifiState ?? false
        cfputils.log('设备状态2', c)
        if (!c) {
            ts({open: true, msg: 'Hub3との通信状況をご確認ください'})
        }

        return c
    }
    const mIot = () => {

        AWS.config.region = "ap-northeast-1"
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'ap-northeast-1:0a1820f1-dbb3-4bca-9227-2a92f6abf0ae',
        })
        return new AWS.IotData({
            endpoint: "a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com",
        })
    }

    const findTouchName = (touchId) => {
        return (
            cfpDevices.find((item) => item.deviceUUID === touchId)?.deviceName ??
            touchId
        )
    }
    const getUserDevices =async () => {
        cfputils.log('获取用户所有设备', gAuth.loginSub,gStripe.customer.subUUID)
        let subUUID=gStripe.customer.subUUID
        cfputils
            .makeAll(
                () =>

                    cfputils.apiPost('/touchpro/company/lock', {op: 'get',companyID:subUUID}),

                setIsOpenDialog,
            )
            .then((res) => {
                cfputils.log('获取用户所有设备', res)

                getComanyDevices(res)

                setAllDevices(prevState => {


                    return [...prevState,...res.filter(item=>!prevState.some(i=>i.deviceUUID===item.deviceUUID))]
                })


            })
            .catch((e) => {
                cfputils.err('获取用户所有设备', e)
            })
    }



    const getShadowStateWifi =async (deviceId) => {
        try {

            const result = await cfputils.makeAll(() => {
                return mIot().getThingShadow(
                    { thingName: 'sesame2', shadowName: deviceId.toUpperCase() }
                ).promise();
            }, setIsOpenDialog);

            let   shadowData=JSON.parse(result.payload)["state"]["reported"]
            console.log("获取当前影子信息",deviceId,JSON.parse(result.payload))
            return {
                wifiState:Object.values(shadowData.wm2s).includes("01"),

              //  t:shadowData?.data?.t||'0',
                battery:`${gUtils.getBatteryPercent(shadowData.battery)}%`

            }; // 假设 result.d 是您需要的数据
        } catch (e) {
            cfputils.log(`获取设备${deviceId}的影子错误`, e);

        }
        return {
            wifiState:false,

            //  t:shadowData?.data?.t||'0',
            battery:`-`

        };
    }
    const getComanyDevices = async (allDevices) => {
        let  subUUID=gStripe.customer.companyID
        cfputils.log('获取认证设备',subUUID)
        try {
            let  res= await cfputils.makeAll(
                () =>
                    cfputils.apiPost('/touchpro/company/lock', {op: 'get',companyID:subUUID}),
                setIsOpenDialog,
            )
            cfputils.log('获取认证设备result',res,allDevices)
            let ssmDevices = [];
            let devices = [];
            res.forEach((row) => {
                if (!isWifiModel(row.deviceModel)) {
                    if (gUtils.isTouchModel(row.deviceModel)) {
                        let  find=allDevices.find(item=>item.deviceUUID===row.deviceUUID)

                        if (find){
                            devices.push(row);
                        }


                    } else {
                        ssmDevices.push(row);
                    }
                }
            });
            if (devices.length>0){
                let promises=devices.map(device=>getShadowStateWifi(device.deviceUUID))
                let results = await Promise.all(promises);
                for (let i = 0; i <devices.length ; i++) {
                    devices[i]={...devices[i],...results[i]}
                }
                cfputils.log('获取认证设备resultwifi',results,devices)
            }
            setcfpSsmDevices(ssmDevices);
            setCfpDevices(devices);
            setisQueryBindDevice(true)

        }catch (e){
            cfputils.err('获取认证设备',e)
        }


    }
    const isWifiModel=(data)=>{
           //deviceModel
        return data==='wm_2'&&data==="hub_3"
    }


    const removeCfpDevices = async (items) => {
        cfputils.log('删除认证设备', items)
        setIsOpenDialog(true)

        // 创建一个数组来存储所有的删除设备的Promise
        const deletePromises = items.map(
            (item) =>
                cfputils.apiDel(pathDevice, {
                    deviceUUID: item.deviceUUID,
                    companyID: gStripe.customer.id,
                })
        )

        // 使用Promise.all来等待所有的删除操作都完成
        try {
            const results = await Promise.all(deletePromises)

            setCfpDevices(prevState => prevState.filter(item=>!items.some(it=>it.deviceUUID===item.deviceUUID)))
            setcfpSsmDevices(prevState => prevState.filter(item=>!items.some(it=>it.deviceUUID===item.deviceUUID)))


            cfputils.log('删除认证设备', results)
        } catch (e) {
            cfputils.err('删除认证设备', e)
        }finally {
            setIsOpenDialog(false)

        }
    }

    const cfpModifyCfpDevicesName = (uuid, editInfo,call) => {
        cfputils.log('修改认证设备',uuid,editInfo)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiPatch(pathDevice, {
                        ...editInfo,
                        companyID: gStripe.customer.id,
                        deviceUUID: uuid,
                    }),
                setIsOpenDialog,
            )
            .then((res) => {

                if ( cfpSsmDevices.some(item=>item.deviceUUID===uuid)){
                    let c = cfpSsmDevices.map((item) => {
                        return item.deviceUUID === uuid
                            ? {...item, deviceName: editInfo.deviceName}
                            : item
                    })

                    cfputils.log('修改认证设备', res)
                    if (call){call()}

                    setcfpSsmDevices(c)
                }else {
                    let c = cfpDevices.map((item) => {
                        return item.deviceUUID === uuid
                            ? {...item, deviceName: editInfo.deviceName}
                            : item
                    })

                    cfputils.log('修改认证设备', res)
                    if (call){call()}

                    setCfpDevices(c)
                }

            })
            .catch((e) => {
                cfputils.err('修改认证设备', e)
            })
    }
    const addSsmDevices = async (item) => {
        cfputils.log('添加ssm設備', item)
        if (item.keyLevel!==0){
            ts({open: true, msg: '！！登録失敗、デバイスの"オーナー"権限が必要です'})
            return
        }
        item.subUUID=gStripe.customer.companyID
        cfputils.log('addSsmDevices',item)

        let   email=gStripe.customer.employeeEmail
        cfputils.makeAll(
            () =>
                cfputils.apiPost('/touchpro/company/lock', {...item,op:'add',employeeEmail:email}),
            setIsOpenDialog,
        )
            .then((res) => {
                console.log("adddevice",res)
                if (res){
                    setcfpSsmDevices(prevState => [...prevState,res])
                }else {
                    ts({open: true, msg: '登録可能数がご利用プランの上限に達しました。プランのアップグレードが必要です。'})

                }


                cfputils.log('addSsmDevices', res)
            })
            .catch((e) => {
                cfputils.err('addSsmDevices', e)
                ts({open: true, msg: '登録可能数がご利用プランの上限に達しました。プランのアップグレードが必要です。'})

            })


    }
    const addCfpDevices = async (items) => {
        cfputils.log('添加认证设备', items)
        setIsOpenDialog(true)
        const addPromisses = items.map(
            (item) =>
                cfputils.apiPost('/touchpro/company/lock', {
                    ...item,
                    subUUID:gStripe.customer.id,
                    isTouchPro:true,
                    op:'add',
                    companyID: gStripe.customer.id,
                })
        )
        try {
            const results = await Promise.all(addPromisses)
            console.log("添加认证设备",results)
            if (results){
                results.map(async item=>{

                    if (gUtils.isTouchModel(item.deviceModel)){

                      let  result=await getShadowStateWifi(item.deviceUUID)

                        setCfpDevices(prevState => [...prevState,{...item,...result}])
                    }else {
                        setcfpSsmDevices(prevState => [...prevState,item])
                    }
                })


            }
            cfputils.log('添加认证设备', results)
        } catch (e) {
            cfputils.err('添加认证设备', e)
        } finally {
            setIsOpenDialog(false)
        }
    }

    const downloadFile = (fileName, call) => {
        cfputils.log('准备下载', fileName)
        cfputils
            .makeAll(
                () =>
                    cfputils.apiGet('/touchpro/download', {
                        queryStringParameters: {
                            bucketName: 'sesamebiz',
                            fileName: fileName,
                        },
                    }),
                setIsOpenDialog,
            )
            .then((res) => {
                if (res.code === 200) {
                    call(null, res.data)
                } else {
                    call(res.message)
                }
                cfputils.log('准备下载', res)
            })
            .catch((err) => {
                call(err)
                cfputils.err('准备下载', err)
            })
    }

    return {
        cfpDevices,
        setCfpDevices,

        cfpChooseDevices,
        allDevices,
        removeCfpDevices, //移除设备
        addCfpDevices, //添加cfp设备



        cfpSsmDevices, //ssm设备
        downloadFile, //下载文件
        cfpModifyCfpDevicesName, //修改设备名称

        mid, //主键ID企业ID

        listMm, //获取企业社员
        touchId,
        setTouchId,
        getWifiState,
        findTouchName,

        isQueryBindDevice,
        setisQueryBindDevice,
        uuidByDevices,
        uuidByMm,
        addSsmDevices,
        findDeviceUUIDName,
        tempMid,
        setTempMid


    }
}

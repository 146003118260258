import {API, Auth,  PubSub} from "aws-amplify"
import {AWSIoTProvider} from "@aws-amplify/pubsub"
import { useEffect,  useState} from "react"
import AWS from "aws-sdk"

import {cfputils, useDialog} from "../components/utils/cfputils"
import {gConfig} from "../chatai/utils/config";


export const useIotCfpCtrl = (
    gAuth,
    gCfp,
    gCfpCards,
    gCfpfp,
    ts,
    gNet,
    gCfpDevice
) => {
    const [subscribes, setSubscribes] = useState([])
    const [isInit, setisInit] = useState(false)
    const setIsOpenDialog = useDialog()

    useEffect(async () => {

        console.log("获取登录状态", gAuth.loginState)
        if (gAuth.loginState === gConfig.loginState.loginIn) {
            await initIot()
            subIotDevices()


        } else {
            subscribes.forEach(({subscription}) => subscription.unsubscribe())
            setSubscribes([])

        }
    }, [gAuth.loginState])
    const initIot = async () => {
        cfputils.log("iotlog", isInit)
        if (!isInit) {
            try {
                setisInit(true)
                await PubSub.addPluggable(
                    new AWSIoTProvider({
                        aws_pubsub_region: "ap-northeast-1",
                        aws_pubsub_endpoint:
                            "wss://a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com/mqtt",
                    })
                )
                const info = await Auth.currentCredentials()
                await API.post("Attendance", `/device/addpolicy`, {
                    body: {
                        id: info.identityId,
                    },
                })
            } catch (err) {
                console.error(err)
            }
        }
    }


    const subIotDevices = () => {
        console.log("获取登录状态", 'subIotDevices', gCfp.cfpDevices.length)
        if (gCfp.cfpDevices.length > 0) {

            gCfp.cfpDevices.forEach((item) => {
                if (!subscribes.some((sub) => sub.deviceId === item.deviceUUID)) {
                    subscribeDevice(item.deviceUUID);
                }


            })
        }

    }
    const hub3TowifiState = (data) => {
        let isConnect = false
        Object.keys(data).forEach((item) => {
            if (data[item] === "02") {
                isConnect = true
            }
        })
        return isConnect
    }


    const iotReceive = (deviceUUID, data) => {
        cfputils.log(`订阅接收${deviceUUID}的信息`, data)

        let op = data.op
        if (op === "0" || op === 0) {
            gCfp.setCfpDevices(
                gCfp.cfpDevices.map((item) => {
                    if (item.deviceUUID === deviceUUID) {
                        let name = gCfp.findTouchName(deviceUUID)
                        cfputils.log(`订阅接收${deviceUUID}的wifi状态`, hub3TowifiState(data.wm2s))
                        item.wifiState = hub3TowifiState(data.wm2s)
                        if (item.wifiState) {
                            ts({open: true, msg: `デバイス${name}は接続済みです`})
                        } else {
                            ts({open: true, msg: `デバイス${name}は接続が中断されました`})
                        }
                    }
                    return item
                })
            )
        } else if (op === "11" || op === 11) {
            let info = data.data.info[0]

            gCfpCards.addCards(deviceUUID, info)

        } else if (op === "12" || op === 12) {
            let info = data.data.info[0]
            gCfpCards.delCards(deviceUUID, info)

        } else if (op === "113" || op === 113) {
            let info = data.data
            gCfpCards.updateName(deviceUUID, info)

        } else if (op === 21 || op === "21") {
            let info = data.data.info[0]
            gCfpfp.addFp("f", deviceUUID, info)
        } else if (op === 22 || op === "22") {
            let info = data.data.info[0]

            gCfpfp.delFp("f", deviceUUID, info)
        } else if (op === "123" || op === 123) {
            let info = data.data
            gCfpfp.updateName("f", deviceUUID, info)

        } else if (op === "133" || op === 133) {
            let info = data.data
            gCfpfp.updateName("p", deviceUUID, info)

        } else if (op === 31 || op === "31") {
            let info = data.data.info[0]
            gCfpfp.addFp("p", deviceUUID, info)

        } else if (op === 32 || op === "32") {
            let info = data.data.info[0]
            gCfpfp.delFp("p", deviceUUID, info)

        } else if (op === "01") {
            let devicesId = data.data.info[0]

            gCfpDevice.settouchDevices((prevState) => [...prevState, devicesId])
        } else if (op === "02") {
            let devicesId = data.data.info[0]
            console.log("当前", gCfpDevice.touchDevices, devicesId)
            gCfpDevice.settouchDevices((prevState) =>
                prevState.filter((item) => item !== devicesId)
            )
        }
     //   updateShadow(deviceUUID,-1,null)

    }

    const subscribeDevice = async (deviceId) => {
        if (subscribes.some((it) => it.deviceId === deviceId)) {
            return false
        }
        const topic = `$aws/things/sesame2/shadow/name/${deviceId}/update/documents`
        //  const topic = `$aws/things/sesame2/shadow/name/${deviceId}/update/accepted`
        const subscription = PubSub.subscribe(topic, {
            provider: "AWSIoTProvider",
        }).subscribe({
            next: (data) => {
                // 处理接收到的消息
                console.log("接受的IOT数据",data.value.current?.state?.reported)
                if (data.value.current?.state?.reported) {
                    let value = data.value.current?.state?.reported?.wm2s
                    if (value && typeof value === 'object') {
                        gCfp.setCfpDevices(prevState => prevState.map(item =>
                            item.deviceUUID === deviceId
                                ? {
                                    ...item,
                                    wifiState: Object.values(value).includes("01")
                                }
                                : item
                        ))
                    }
                    iotReceive(deviceId, data.value.current?.state?.reported)


                }



            },
            error: (error) => {
                console.error("订阅错误", "Error during subscription", error)
            },
            complete: () => cfputils.log("subscribeDevice", "complete"),
        })
        setSubscribes((prevSubscribes) => [
            ...prevSubscribes,
            {deviceId, subscription},
        ]);


        return true
    }
    const cancelSubscriptions = () => {


        /*      subscribes.forEach(({subscription}) => subscription.unsubscribe())
              setSubscribes([]) */
    }


    const mIot = () => {

        AWS.config.region = "ap-northeast-1"
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'ap-northeast-1:0a1820f1-dbb3-4bca-9227-2a92f6abf0ae',
        })
        return new AWS.IotData({
            endpoint: "a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com",
        })
    }


    function publishAsync(cmd) {
        let iot = mIot()
        return new Promise((resolve, reject) => {
            iot.publish(cmd, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    const checkIsAddDelCmd = (op) => {
        if (op === gConfig.cmdCode.passwordSet || op === gConfig.cmdCode.passwordSetFinish) {
            gCfpCards.setTemDeviceCards([])
            gCfpfp.setTemDeviceFingers([])
        }
        if (op === gConfig.cmdCode.fingerSet || op === gConfig.cmdCode.fingerSetFinish) {
            gCfpCards.setTemDeviceCards([])
            gCfpfp.setTemDevicePasswords([])
        }
        if (op === gConfig.cmdCode.cardSet || op === gConfig.cmdCode.cardSetFinish) {
            gCfpfp.setTemDeviceFingers([])
            gCfpfp.setTemDevicePasswords([])
        }
    }

    const sendCmd = async (deviceID, op, value, isShowDialog = true) => {
        try {
            if (isShowDialog) setIsOpenDialog(true)
            checkIsAddDelCmd(op)
            let params = {
                op: `${op}`,
            }

            if (op === gConfig.cmdCode.cardPut || op === gConfig.cmdCode.cardDelete) {
                params.c = value
            }
            if (op === gConfig.cmdCode.fingerDelete || op === gConfig.cmdCode.fingerPut) {
                params.f = value
            }
            if (op === gConfig.cmdCode.passwordPut || op === gConfig.cmdCode.passwordDelete) {
                params.p = value
            }
            let cmd = {
                topic: `stp${deviceID}cmd`,
                payload: JSON.stringify(params),
                qos: 0, // QoS等级，可以是0或1
            }
            const data = await publishAsync(cmd);
            console.log("send topic", deviceID, cmd, data);
        } catch (err) {
            console.log("send topic", "error", err);
        } finally {
            if (isShowDialog) setIsOpenDialog(false)

        }

    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const sendCmds = async (deviceID, op, values) => {
        if (!Array.isArray(values) || values.length === 0) {
            console.error("Values should be a non-empty array");
            return;
        }
        setIsOpenDialog(true)
        for (let i = 0; i < values.length; i++) {
            await sendCmd(deviceID, op, values[i])
            await delay(1600);
        }
        setIsOpenDialog(false)
    }


    return {

        subIotDevices,

        cancelSubscriptions,
        sendCmd,
        sendCmds,

    }
}

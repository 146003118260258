import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { Buffer } from 'buffer'
import {gConfig} from "../chatai/utils/config";
export const useCompanyHistory = (gAuth) => {
  const [isPending, setIsPending] = useState(false)
  const [companyDevices, setCompanyDevices] = useState([])
  const [history, setHistory] = useState([])

  useEffect(() => {
    if (gAuth.loginState === gConfig.loginState.loginOut) {
      setHistory([])
      setIsPending(false)
    }
  }, [gAuth.loginState])
  const handelGetDevices = async (companyID, historyCallback) => {
    setIsPending(true)
    await API.get('Attendance', `/history/device `, {
      queryStringParameters: {
        // companyID: 'cus_MnryRkG87mPQtF', //資料爆多的客人
        companyID,
      },
    })
      .then((res) => {
        // console.log('res', res)
        setCompanyDevices(res)
        handleGetHistory(new Date().getTime(), res, historyCallback)
      })
      .catch((err) => {
        console.log(err)

      }).finally(()=>{
          setIsPending(false)
        })
  }

  const handleGetHistory = async (ts_from, devices, historyCallback) => {
   // console.log('timestamp', new Date(ts_from), 'devices', devices)
    //30 days
    let period = 2592000000
    // console.log('進去device', devices)
    let deviceInfo = devices
      .filter((device) => ts_from > device.register_time)
      .map((device) => {
        device.ts_end =
          device.register_time > ts_from - period
            ? device.register_time
            : ts_from - period
        device.ts_from = ts_from
        return device
      })
    setCompanyDevices(deviceInfo)
    // console.log('deviceInfo', deviceInfo)
    setIsPending(true)
    await API.get('Attendance', `/history`, {
      // await API.get('Attendance_seoul', `/test_history`, {
      queryStringParameters: { device_info: JSON.stringify(deviceInfo) },
    })
      .then((res) => {
        // console.log('歷史=>', res)
        let histories = res
          .map((item) => {
            item.result.map((history) => {
              history.deviceName = item.device.deviceName
              // history.history_tag = Buffer.from(history.history_tag, 'base64').toString('utf8')

              if (history.history_tag) {
                history.history_tag = Buffer.from(
                  history.history_tag,
                  'base64',
                ).toString('utf8')
              }
              return history
            })
            return item.result
          })
          .flat(2)
          .sort(function (x, y) {
            return y.timestamp - x.timestamp
          })

        histories = histories.filter(
          (history) =>
            history.type === 1 ||
            history.type === 2 ||
            history.type === 6 ||
            history.type === 7 ||
            history.type === 8 ||
            history.type === 14 ||
            history.type === 15 ||
            history.type === 16 ||
            history.type === 17 ||
            history.type === 19 ||
            history.type === 20,
        )
        // console.log('histories', histories)
        setHistory((oldArr) => [...oldArr, ...histories])

      })
      .then(() => {
        historyCallback()
      })
      .catch((err) => {
        console.log(err)
      }).finally(()=>{
          setIsPending(false)
        })
  }



  return {
    isPending,
    setIsPending,
    history,
    setHistory,
    handelGetDevices,
    handleGetHistory,
    companyDevices,
  }
}

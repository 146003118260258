import React, {useState} from 'react'

import {useAuthState} from '../apis/use_aws_auth'
import {useStripeInfo} from '../apis/use_stripe_info'
import {useLoginEmployee} from '../apis/use_login_employee_info'
import {useCompanyHistory} from '../apis/use_company_history'
import {useCompanyDevice} from '../apis/use_company_device'
import {useCompanyEmployee} from '../apis/use_company_employee'
import {useCreditCard} from '../apis/use_credit_card'
import {useIotCtrl} from '../apis/use_iot_ctrl'
import {useIotCfpCtrl} from '../apis/use_cfp_iot'
import {useGfun} from '../apis/gfun'
import {useCfpCtrl} from '../apis/use_cfp_ctrl'
import {GSnackbar} from '../components/cfp/vsnackbar'
import CustomModal from '../components/attendance/customModal'
import {useCfpCardCtrl} from "../apis/use_cfp_cardctrl";
import {useCfpFpCtrl} from "../apis/use_cfp_fpctrl";
import {useCfpBindDevice} from "../apis/use_cfp_binder_device";
import {useNetworkStatus} from "../apis/use_network";
import {useGroupDevice} from "../apis/use_group_device";
import {useMemberDevice} from "../apis/use_group_member";
import {useGDev} from "../apis/use_g_dev";


export const GlobalStateContext = React.createContext({})
const GlobalContextProvider = ({children, location}) => {
    const [gsn, ts] = useState({
        open: false,
        msg: '',
    })



    const gAuth = useAuthState()
    const gNet = useNetworkStatus()
    const gStripe = useStripeInfo(gAuth,ts)

    const gLoginEmployee = useLoginEmployee(gStripe, gAuth)
    const gCompanyHistory = useCompanyHistory(gAuth)

    const gIot = useIotCtrl(gAuth)

    const gCompanyDevice = useCompanyDevice(gLoginEmployee, gAuth, gStripe)
    const gCompanyEmployee = useCompanyEmployee(gLoginEmployee, gAuth,gStripe,ts)
    const gCreditCard = useCreditCard(gAuth, gLoginEmployee, gStripe)
    const gf = useGfun()
    const gCfpfp = useCfpFpCtrl(gAuth)
    const gCfp = useCfpCtrl(gAuth, gLoginEmployee, gCompanyEmployee, gStripe, ts)
    const gCfpCards = useCfpCardCtrl(gAuth,gCfp,gCfpfp)

    const gCfpDevice = useCfpBindDevice(gCfp, ts)
    const gIotCfp = useIotCfpCtrl(gAuth, gCfp, gCfpCards, gCfpfp, ts,gNet,gCfpDevice)
    const gGroupDevice = useGroupDevice(gStripe,gAuth)
    const gMemberDevice = useMemberDevice(gAuth,gStripe)
    const gDev = useGDev(gStripe,gAuth)
    const [customModalOpen, setCustomModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [modalTitle, setModalTitle] = useState('')

    return (
        <GlobalStateContext.Provider
            value={{
                gAuth,
                ts,
                gStripe,
                location,
                gLoginEmployee,
                gCompanyHistory,
                gCompanyDevice,
                gCompanyEmployee,
                gCreditCard,
                gIot,
                gf,
                gGroupDevice,
                gMemberDevice,
                gCfp,
                gCfpfp,
                gCfpDevice,
                gIotCfp,
                gCfpCards,
                customModalOpen,
                setCustomModalOpen,
                modalContent,
                setModalContent,
                modalTitle,
                setModalTitle,
                gNet,
                gDev
            }}
        >
            {children}
            <GSnackbar value={gsn}/>
            <CustomModal
                open={customModalOpen}
                onClose={() => setCustomModalOpen(false)}
            >
                {modalContent}
            </CustomModal>
        </GlobalStateContext.Provider>
    )
}
export default GlobalContextProvider

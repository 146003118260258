
const cmdCode={
    cardPut:14,
    cardDelete:15,
    cardSet:18,
    cardSetFinish:19,
    fingerPut:24,
    fingerDelete:25,
    fingerSet:28,
    fingerSetFinish:29,
    passwordPut:34,
    passwordDelete:35,
    passwordSet:38,
    passwordSetFinish:39,
}
const loginState={
    loginIn:"signIn",
    loginOut:"signOut",
    configured:'configured',

}
const model={
    ssm_touch:'ssm_touch',
    ssm_touch_pro:'ssm_touch_pro',
    sesame_face:'sesame_face',
    ssmbot_1:'ssmbot_1',
    bot_2:'bot_2',
    sesame_5_us:'sesame_5_us',
    sesame_5_pro:'sesame_5_pro',
    sesame_5:'sesame_5',
    sesame_4:'sesame_4',
    sesame_2:'sesame_2',
}
const touchType={
    card:'card',
    finger:'finger',
    face:'face',
    palm:'palm',
    password:'password',

}
export const gConfig = {
    model,
    cmdCode,
    loginState,
    touchType

};

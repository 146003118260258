import React, {createContext, useContext, useEffect, useState} from "react";

import { IconButton, Typography} from "@mui/material";
import ReactDOM from "react-dom";
import {Box} from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {Link} from "gatsby";

export const BannerContext = createContext()

export const BannerCtrl = ({children}) => {
    const [isShow, setIsShow] = useState(false)
    const [msg, setMsg] = useState("最新バージョンのBizを利用開始")
    const url = 'https://biz.candyhouse.co'

    return (
        <BannerContext.Provider value={{isShow, setIsShow, msg, setMsg, url}}>
            {children}
            <CmBanner/>
        </BannerContext.Provider>
    )
}

export const CmBanner = () => {
    const {isShow, msg, url} = useContext(BannerContext)
    useEffect(() => {
        console.log("BizBanner is mounted");
    }, []);


    return typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
            <BizBanner isShow={isShow} msg={msg} url={url}/>,
            document.body,
        );
}
export const BizBanner = ({isShow, msg, url}) => {


    const {setIsShow} = useContext(BannerContext);
    const handleClose = () => {

        setIsShow(false)
    }

    return (
        <>
            {isShow && (
                <Box sx={{
                    position: 'fixed', // 固定位置
                    top: '5%', // 顶部
                    left: '50%', // 水平居中
                    transform: 'translateX(-50%)', // 配合left使用，确保准确居中
                    width: 'auto', // 根据内容自动调整宽度
                    margin: '10px',
                    background: '#333333',
                    color: 'white', // 文字颜色
                    padding: '8px', // 内边距
                    borderRadius: '15px', // 圆角
                    display: 'flex', // 使用flex布局
                    justifyContent: 'space-between', // 子元素间距
                    alignItems: 'center', // 垂直居中

                    zIndex: 1000, // 确保在顶层
                }}>

                    <Link href={url} target="_blank" rel="noopener noreferrer">
                        <Typography
                            variant="h4"
                            sx={{
                                ml: "40px",
                                color: '#fff',
                                fontSize: "26px",
                                '&:hover': {
                                    textDecoration: 'underline'
                                },
                            }}
                        >
                            {msg}
                        </Typography>
                    </Link>
                    <IconButton onClick={handleClose} sx={{background: '#fff', ml: '80px'}}>
                        <CloseIcon sx={{color: '#68C6C8'}}/>
                    </IconButton>
                </Box>
            )}
        </>
    )

}

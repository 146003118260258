import React, {useEffect, useState} from "react";
import {Snackbar} from "@mui/material";

export const GSnackbar = ({value}) => {
const [data,setData]=useState(value)
      useEffect(()=>{

        setData(value)


      },[value])


 const   closeSnackbar=()=>{
    setData(prevState => ({
         ...prevState,
         open: false
     }));
    }
    return (
        <Snackbar
            anchorOrigin={{vertical:'center' ,horizontal: 'center'  }}
            open={data.open}
            autoHideDuration={1500}
            onClose={closeSnackbar}
            message={data.msg||"未知信息"}

        />
    );
}

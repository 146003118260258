import { useState, useEffect } from 'react';

export const useNetworkStatus=() =>{

    const isBrowser = typeof window !== 'undefined';
    const [isOnline, setIsOnline] = useState(isBrowser ? navigator.onLine : true);
    const updateNetworkStatus = () => {
        setIsOnline(navigator.onLine);
    };


    useEffect(() => {
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };
    }, []);

    return {
        isOnline,

    }
}


